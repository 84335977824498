@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';

.reject-cert-modal {
	position: relative;
	display: flex;
	flex-direction: column;

	.certificate-title {
		font-weight: 500;
		font-size: 28px;
		line-height: 32px;
	}

	.certificate-dates {
		display: flex;
		align-items: center;
		margin-top: 20px;

		& > span {
			padding: 2px 6px;
			margin-left: 8px;
			border-radius: 12px;
			background-color: $color-gray;
			color: #fff;
		}
	}

	.certificate-address {
		margin-top: 20px;
		& > span {
			font-size: 16px;
			line-height: 24px;
		}
	}

	.certificate-code {
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: center;

		& > p {
			font-weight: 500;
			font-size: 24px;
			line-height: 24px;
		}

		& > span {
			margin-right: 10px;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			opacity: 0.6;
		}
	}

	.certificate-buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 30px;

		button + button {
			margin-top: 10px;
		}
	}

	.certificate-status {
		display: flex;
		justify-content: center;
		margin-top: 20px;
		color: $color-red;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
	}

	.rejecet-certificate,
	.approve-certificate {
		position: absolute;
		height: 0;
		opacity: 0;
		display: none;
		bottom: -40px;
		left: -40px;
		width: calc(100% + 80px);
		padding: 20px 40px 30px 40px;
		background-color: #fff;
		border-radius: 0 0 8px 8px;
		border-top: 1px solid rgba(77, 77, 77, 0.1);
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
		transition: $transition-main;

		&.show {
			height: auto;
			display: block;
			opacity: 1;
			transition: $transition-main;
		}

		textarea {
			min-height: 80px;
		}

		.buttons-block {
			margin-top: 20px;
			display: flex;
			justify-content: space-between;

			& > button {
				width: 48%;
			}
		}
	}
}
