@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.dropdown-main-container {
	position: relative;
	width: 100%;
	max-width: 420px;

	&:not(.focus) {
		transition: z-index 0s 0.2s;
		z-index: 1;

		.dropdown-options {
			opacity: 0;
			top: 39px;
			pointer-events: none;
			user-select: none;
		}
	}

	&.focus {
		z-index: 3;

		.label {
			border-color: $color-blue-focus;
		}

		.dropdown-options {
			opacity: 2;
			top: 47px;
		}
	}

	&.focus,
	&.filled {
		.label input {
			color: $color-text-black;
		}
	}

	.label {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		height: 48px;
		width: 100%;
		padding-left: 16px;
		background-color: $color-app-main;
		border: 1px solid $color-border;
		transition: $transition-main;
		cursor: pointer;
		z-index: 2;

		input {
			width: 100%;
			height: 100%;
			font-size: 16px;
			color: $color-input-placeholder;
			transition: $transition-main;
			border: none;
			outline: none;
		}
	}

	.btn-clear {
		transition: $transition-main;

		&:hover {
			background-color: $color-blue-ghost;
		}
	}

	.opt-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		z-index: 1;
	}
}
