@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.search-input-container {
	display: flex;
	align-items: center;
	position: relative;
	height: 36px;
	width: 100%;
	max-width: 360px;
	padding: 0 8px;
	background-color: $color-app-main;
	border: 1px solid $color-border;
	border-radius: 8px;

	&.modal {
		max-width: 100%;
	}

	& > :first-child {
		opacity: 0.5;
	}

	input {
		flex: 1;
		height: 100%;
		margin-left: 4px;
		background-color: $color-app-main;
		border: none;
		outline: none;
		font-size: 16px;
		transition: $transition-main;

		&::placeholder {
			color: #95979d;
		}
	}
}
