@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.points-modal__search-wrapper {
	position: relative;

	&.active {
		& > .search-input-container {
			z-index: 1;
		}

		& > .points-modal__overlay {
			display: block;
		}
	}

	& > .points-modal__overlay {
		display: none;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	& > .search-dropdown {
		display: none;
		position: absolute;
		z-index: 2;
		width: 100%;
		list-style: none;
		opacity: 0;
		top: 50%;
		background: $color-landing-background;
		border: 1px solid $color-border;
		box-shadow: 0px 30px 24px rgba(67, 72, 114, 0.035),
			0px 12px 10px rgba(67, 72, 114, 0.0456112),
			0px 4px 4px rgba(67, 72, 114, 0.07);

		li {
			padding: 4px 7px;
			cursor: pointer;

			&:hover {
				background-color: $color-border-light;
			}
		}

		&.active {
			display: block;
			opacity: 1;
			top: 34px;
		}
	}
}
