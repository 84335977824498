.comments-table {
	& > .comments {
		.table-titles-block {
			grid-template-columns: 316px 48px 300px 90px 80px 120px;
		}

		.table-rows-block {
			& > .table-row {
				grid-template-columns: 316px 48px 300px 90px 80px 120px;
				cursor: pointer;

				&:nth-child(odd) {
					background-color: #f5f7f9;
					width: 100%;
					border-radius: 8px;
				}
			}
		}
	}
}
