@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.users-page {
	height: 100%;
	width: 100%;

	& > .page-wrapper {
		padding: 16px 28px;
	}

	.left-column {
		.filters-header__reset {
			width: min-content;
			margin-bottom: 10px;
			font-size: 16px;
			font-weight: 600;
			margin-right: 10px;

			& > i {
				margin-left: 10px;
			}
		}
	}

	&.user-item-page {
		background-color: #fff;
		min-height: 100%;

		& > .page-wrapper {
			padding: 0;
			height: 100%;
		}
	}

	.page-wrapper {
		height: 100%;

		.columns-container {
			height: 100%;
		}
	}

	.panel-title {
		margin-bottom: 24px;
		font-size: 20px;
		font-weight: 500;
	}

	.right-column {
		height: 100%;
	}

	.sections-container {
		display: grid;
		row-gap: 8px;
		grid-auto-rows: 20px;
		position: relative;

		&::after {
			content: '';
			display: block;
			position: absolute;
			left: -20px;
			top: 0;
			height: 20px;
			width: 2px;
			background-color: $color-blue-focus;
			transition: $transition-main;
		}

		&.s1 {
			&::after {
				top: 0;
			}

			.item:nth-child(1) {
				color: $color-blue-focus;
			}
		}

		&.s2 {
			&::after {
				top: 28px;
			}

			.item:nth-child(2) {
				color: $color-blue-focus;
			}
		}

		.item {
			font-size: 16px;
			font-weight: 500;
			cursor: pointer;
			transition: $transition-main;
		}
	}

	.modal-container {
		.users-filters {
			.users-filters-wrapper {
				max-height: 600px;
			}
		}
	}

	@include max-width(768px) {
		& > .page-wrapper {
			padding: 16px 13px;
		}
	}
}
