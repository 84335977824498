@import '../../../../assets/styles/mixins';

.quota__row {
	position: relative;
	display: grid;
	grid-template-columns: 6fr 2fr 0.5fr;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	.quota__row-name {
		background-color: #fafafa;
		border-radius: 8px;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		margin-right: 16px;
		padding: 12px 20px;
		border: 1px solid #fafafa;
	}

	.quota__row-input-block {
		display: flex;

		.quota__row-num {
			display: flex;
			position: relative;
			justify-content: space-between;
			max-width: 165px;
			margin-right: 16px;
			border: 1px solid rgba(55, 59, 79, 0.24);
			padding: 12px 40px 12px 16px;
			line-height: 24px;
			font-weight: normal;
			font-size: 16px;
			background-color: #fafafa;
			outline: none;

			span {
				color: #373b4f80;
			}

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}

		.quota__row-div-block {
			display: flex;
			position: relative;
			justify-content: space-between;
			width: 100%;
			margin-right: 16px;
			border: 1px solid rgba(55, 59, 79, 0.24);
			padding: 12px 40px 12px 16px;
			line-height: 24px;
			font-weight: normal;
			font-size: 16px;
			background-color: #fafafa;
		}

		.quota__row-num-label {
			position: relative;
			display: block;
			width: 0;
			height: 100%;

			&:after {
				position: absolute;
				top: 13px;
				right: 30px;
				display: block;
				width: 30px;
				height: 20px;
				content: 'мес.';
			}
		}
	}

	@include max-width(768px) {
		grid-template-columns: minmax(200px, 6fr) 190px 40px;
	}
}
