.office-block-container {
	&__list {
		display: flex;
		justify-content: space-between;
		width: 100%;

		& > .empty-element-block {
			.empty-element-block__container {
				height: 120px;
				margin-bottom: 0;
			}
		}
	}
}
