@import '../../../assets/styles/colors';

.questionnaire-tabs {
	display: flex;
	flex-direction: column;
	max-width: 600px;
	max-height: 600px;
	overflow: hidden;

	&::-webkit-scrollbar {
		width: 0;
	}

	&__header {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		margin-bottom: 22px;

		.questionnaire-tab-item + .questionnaire-tab-item {
			margin-left: 20px;
		}

		&::after {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			background-color: $color-panel-border;
			content: '';
		}
	}

	&__content {
		width: 100%;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 0;
		}

		.questionnaire-content-item + .questionnaire-content-item {
			margin-top: 20px;
		}
	}

	.placeholder {
		font-size: 14px;
		opacity: 0.7;
	}
}
