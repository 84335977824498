@import '../../../assets/styles/mixins';

.quota__header {
	display: grid;
	grid-template-columns: 6fr 2.5fr;
	margin: 20px 0 14px;

	.quota__header-item {
		font-weight: bold;
		font-size: 12px;
		line-height: 100%;
		text-transform: uppercase;
		color: #373b4f80;
	}

	@include max-width(768px) {
		grid-template-columns: minmax(200px, 6fr) 190px;
	}
}
