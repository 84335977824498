@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';

.questionnaire-tab-item {
	padding: 0 2px 10px;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	color: $color-text-black;
	border-bottom: 2px solid transparent;
	opacity: 0.7;
	cursor: pointer;
	transition: $transition-main;

	&:hover {
		color: $color-blue-focus;
		opacity: 1;
	}

	&.active {
		position: relative;
		color: $color-blue-focus;
		border-bottom: 2px solid $color-blue-focus;
		opacity: 1;
	}
}
