@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/animations';
@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';

.table-row {
	display: grid;
	grid-auto-flow: column;
	border-radius: 8px;
	padding: 10px 0;
	transition: $transition-main;
	@include shift-out(-8px);

	&.before {
		opacity: 0.5;
	}

	& > .row-item {
		display: flex;
		align-items: center;
		padding: 0 8px;
		font-size: 16px;
		line-height: 24px;
		transition: $transition-main;

		& > img {
			width: 48px;
			height: 48px;
			min-width: 48px;
			min-height: 48px;
			border-radius: 4px;
			object-fit: cover;
		}

		& > .avatar {
			width: 32px;
			height: 32px;
			min-width: 32px;
			min-height: 32px;
			border-radius: 50%;
			object-fit: cover;

			&.plug {
				&-1 {
					background-color: $color-avatar-coral;
				}
				&-2 {
					background-color: $color-avatar-lagoon;
				}
				&-3 {
					background-color: $color-avatar-gray;
				}
				&-4 {
					background-color: $color-avatar-purple;
				}
				&-5 {
					background-color: $color-avatar-mustard;
				}
				&-6 {
					background-color: $color-avatar-lavender;
				}
				&-7 {
					background-color: $color-avatar-mint;
				}
				&-8 {
					background-color: $color-skill-noob;
				}
				&-9 {
					background-color: $color-avatar-lavender;
				}
				&-0 {
					background-color: $color-avatar-lagoon;
				}
			}
		}

		& > .text {
			overflow: hidden;
			text-overflow: ellipsis;
			width: 100%;
			white-space: nowrap;
		}

		& > .overflowText {
			width: 280px;
			word-wrap: break-word;
		}

		& > .unpublic {
			width: 100%;
			display: flex;
			justify-content: center;

			&.delete {
				p {
					background-color: #eb5757;
				}
			}

			& > p {
				display: flex;
				justify-content: center;
				min-width: 24px;
				min-height: 24px;
				padding: 6px;
				line-height: 12px;
				border-radius: 50%;
				color: #fff;
				background-color: #bdbdbd;
			}
		}

		& > .request-count {
			width: 100%;
			display: flex;
			justify-content: center;

			& > p {
				display: flex;
				justify-content: center;
				min-width: 24px;
				min-height: 24px;
				padding: 6px;
				background-color: $color-blue-focus;
				border-radius: 50px;
				color: $color-app-main;
				padding: 0px 6px;

				&.gray {
					background-color: #bdbdbd;
				}
			}
		}

		& > .image-plug {
			width: 48px;
			height: 48px;
			@include bg-image('svg/nooffice.svg', center, contain);
		}

		& > .avatar-plug {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			@include bg-image('svg/nooffice.svg', center, contain);
		}

		& > .points-value {
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: flex-start;

			&.positive {
				color: $color-green;
			}

			&.negative {
				color: $color-red;
			}

			& > .icon-wrapper {
				width: 12px;
				min-width: 12px;
				height: 12px;
				margin-right: 8px;

				i {
					display: block;
					width: 100%;
					height: 100%;

					@include bg-image('svg/x-point.svg', center);
				}
			}
		}

		& > .user-status {
			display: flex;
			.icon-container {
				position: relative;
				top: 2px;
			}

			.text {
				font-size: 16px;

				&.placeholder {
					opacity: 0.5;
				}

				&.black {
					color: $color-text-black;
				}

				&.red {
					color: $color-red;
				}

				&.green {
					color: $color-green;
				}

				&.blue {
					color: $color-blue-focus;
				}
			}
		}

		& > .comment-label {
			padding: 8px 10px;
			font-size: 12px;
			line-height: 140%;
			text-transform: uppercase;
			color: $color-app-main;
			border-radius: 6px;

			&.new {
				background-color: $color-green;
			}

			&.viewed {
				background-color: $color-gray;
			}

			&.rejected {
				background-color: $color-red;
			}
		}

		& > .delete-button {
			margin: 0;
			padding: 0;
			width: 100%;
			max-width: 55px;
			height: 20px;
			outline: none;
			border: none;
			background-color: transparent;
			cursor: pointer;

			@include bg-image('svg/delete-black.svg', center, contain);
		}
	}

	&.delete {
		border: 1px solid $color-red;
	}
}

.events .table-row {
	grid-template-columns: 68px 394px 130px 130px 208px 145px 135px 55px;
}

.users-page .table-row {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 64px 240px 220px 142px 136px 175px 175px 154px 120px 32px;
	padding: 2px 0;
	transition: $transition-main;
	@include shift-out(-8px);

	&:hover {
		background-color: #e8eeff;
	}

	@include max-width(768px) {
		grid-template-columns: 40px 240px 220px 142px 136px 354px 154px 120px 32px;
	}
}
