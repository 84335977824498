@import '../../assets/styles/_colors';

.pdf-wrapper {
	.pdf__downdload-link {
		.label {
			font-weight: 600;
			font-size: 16px;
			letter-spacing: 0.04em;
			line-height: 28px;
			opacity: 0.8;
			&:hover {
				color: $color-blue-focus;
			}
			&:active {
				opacity: 0.5;
				color: $color-blue-focus;
			}
		}
	}
}
