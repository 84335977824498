@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';

.requests-list {
	.table {
		padding: 0;
		border: 0;
	}
}

.button-wrapper {
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;

	.button-main {
		.icon-wrapper {
			i {
				@include bg-image('svg/send.svg', auto);
			}
		}
	}
}