.points-modal{
    display: flex;
    flex-direction: column;

    &__title{
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0.04em;
        margin-bottom: 10px;
    }

    .search-input-container{
        border-radius: 0;
        margin-bottom: 10px;
    }

    .input-main-container{
        margin-bottom: 10px;
    }

    .textarea-field{
        textarea{
            min-height: 100px;
            margin-bottom: 40px;
        }
    }

    &__buttons{
        display: flex;

        .button-main.md1{
            width: 30%;
        }

        .button-main.md2{
            width: 70%;
            margin-left: 15px;
        }
    }
}