.info-panel-tags {
	width: 50%;

	&__title {
		margin-bottom: 16px;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
	}

	&__add {
		display: flex;
		align-items: center;
		margin-bottom: 32px;
		width: 50%;

		& > .button-main {
			margin-left: 16px;
		}
	}

	&__items {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 8px;
	}
}
