@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/mixins';

.content-item-language-line {
	justify-content: flex-start;
	align-items: center;
	font-size: 16px;
	line-height: 24px;

	&__label {
		margin-right: 8px;
	}

	&__value {
		opacity: 0.7;
	}

	@include max-width(480px) {
		align-items: flex-start;
	}
}
