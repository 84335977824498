.date-period__block {
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > p {
		margin-right: 10px;

		&.end {
			margin-left: 10px;
		}
	}
	.react-datepicker-popper {
		z-index: 2;
	}
}
