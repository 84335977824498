@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/mixins';

.content-item-line {
	display: flex;
	align-items: flex-start;
	color: $color-text-black;

	&__label {
		width: 165px;
		margin-right: 22px;
		font-size: 14px;
		line-height: 20px;
		opacity: 0.7;
	}

	&__link {
		font-size: 16px;
		line-height: 24px;
		color: $color-blue-focus;
	}

	&__value {
		font-size: 16px;
		line-height: 24px;
	}

	&__link {
		font-size: 16px;
		line-height: 24px;
		color: $color-blue-focus;
	}

	@include max-width(480px) {
		flex-direction: column;
	}
}
