@import '../../../assets/styles/colors';

.office__page {
	height: 100%;

	& > .page-wrapper:first-child {
		padding: 0;
		height: 65px;
	}

	.office__header {
		display: flex;
		justify-content: space-between;
		position: fixed;
		width: 100%;
		background-color: $color-app-background;
		padding: 10px 40px 10px 16px;
		z-index: 10;

		&-buttoms {
			display: flex;
		}
	}

	.column {
		display: block;
	}

	.columns-container > .column {
		margin-left: 32px;
	}

	.page-wrapper:last-child {
		background-color: $color-app-main;

		.left-column {
			display: flex;
			max-width: 50%;
		}

		.office__title {
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			margin-bottom: 16px;
		}
	}
}
