@import '../../../../assets/styles/colors';

.item-options-absolute {
	position: absolute;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	width: 100%;
	height: 100%;
	top: 0;
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.6);

	&__change,
	&__delete {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-bottom: 10px;
	}
}

.item-options {
	display: flex;
	margin-top: 10px;

	&__change {
		margin-right: 28px;
		font-size: 12px;
		line-height: 13px;
		cursor: pointer;
	}

	&__delete {
		color: $color-red;
		font-size: 12px;
		line-height: 13px;
		cursor: pointer;
	}
}
