@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.table-header-component {
	display: flex;
	align-items: center;
	height: 56px;
	padding-left: 18px;
	padding-right: 14px;
	background-color: $color-app-main;
	border: 1px solid $color-border-light;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	transition: $transition-main;

	&.stanalone {
		border-radius: 8px;
	}

	.title {
		font-size: 1.5em;
		line-height: 120%;
		font-weight: 500;
	}

	.header-tools {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.round-icon-button {
			margin-left: 8px;
			background-color: transparent;
			border-radius: 50%;
			transition: $transition-main;

			&:hover {
				background-color: $color-blue-ghost;
			}
		}

		.tabs-select-wrapper {
			margin-top: 15px;
		}

		.header-input-mobile {
			display: flex;
			align-items: center;
			width: 100%;
		}
	}
}

.users-page {
	.table-header-component {
		@include max-width(1440px) {
			flex-wrap: wrap;
			height: auto;

			& > .title {
				padding-top: 15px;
				margin-right: 15px;
			}

			& > .header-tools {
				& > .tabs-select-wrapper {
					padding: 0;
				}
			}
		}

		@include max-width(768px) {
			padding-left: 9px;
			padding-right: 9px;

			& > .title {
				width: 100%;
			}

			& > .header-tools {
				flex-wrap: wrap;
				margin-top: 15px;
			}
		}
	}
}
