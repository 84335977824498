@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.users-count {
	padding: 20px 0;

	color: $color-input-placeholder;
	text-align: center;

	@include max-width(768px) {
		margin: 0;
		padding: 20px 0;
	}
}
