@import '../../../../assets/styles/colors';

.filter_range {
	width: 100%;
	position: relative;
	padding-top: 40px;

	.tooltip {
		position: absolute;
		top: 0;
		min-width: 150px;
		transform: translateX(-50%);
		background-color: white;
		box-shadow: 0px -30px 24px rgba(67, 72, 114, 0.035), 0px -12px 10px rgba(67, 72, 114, 0.0456112),
			0px -4px 4px rgba(67, 72, 114, 0.07), 0px 30px 24px rgba(67, 72, 114, 0.035),
			0px 12px 10px rgba(67, 72, 114, 0.0456112), 0px 4px 4px rgba(67, 72, 114, 0.07);
		border-radius: 8px;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		opacity: 1;
		visibility: visible;
		padding: 7px 35px 7px 20px;
		z-index: 1;
		white-space: nowrap;

		i {
			position: absolute;
			top: 52%;
			right: 20px;
			transform: translateY(-50%);
			background-image: url('../../../../assets/media/svg/x-point.svg');
			background-repeat: no-repeat;
			background-size: cover;
			width: 13px;
			height: 13px;
		}
	}

	.ant-slider-handle {
		border: 2px solid $color-blue-focus;
		width: 12px;
		height: 12px;
		background-color: $color-blue-focus;
	}
	.ant-slider-track {
		height: 2px;
		background-color: $color-blue-focus;
	}
	.ant-slider-rail {
		opacity: 0.2;
		border: 1px solid $color-blue-focus;
		height: 0;
	}

	.ant-slider:hover .ant-slider-handle {
		background-color: #69c0ff;
	}
}
