@import '../../assets/styles/colors';

.preview {
	margin: 40px 0;
	height: 160px;
	min-width: 250px;
	width: 100%;
	border-radius: 12px;
	overflow: hidden;
	background: $color-gray-light center;
	background-size: cover;
	border: 2px dashed $color-input-placeholder;
	cursor: pointer;

	&__label {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		cursor: pointer;

		& > span {
			color: $color-input-placeholder;
			font-size: 16px;
			font-weight: 500;
		}
	}

	&__input {
		display: none;
	}

	&.isLoaded {
		border: none;

		& > .preview__label {
			& > span {
				visibility: hidden;
			}
		}
	}

	&.error:not(.isLoaded) {
		border: 2px dashed $color-red;

		& > .preview__label {
			& > span {
				color: $color-red;
			}
		}
	}
}

.save-preview {
	margin-top: 20px;
}
