@import '../../../../../assets/styles/colors';

.quota__input-wrapper {
	display: flex;
	align-items: center;
	position: relative;

	.quota__label {
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		background-color: #FAFAFA;
		border-radius: 8px;
		padding: 12px 20px;
		margin-bottom: 8px;
		width: 100%;
		margin-left: 35px;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			left: -35px;
			top: 50%;
			transform: translateY(-50%);
			width: 16px;
			height: 16px;
			border-radius: 50%;
			border: 1px solid rgba(48, 50, 59, 0.89);
			background-color: $color-app-main;
			transition: 0.3s all;
			background-image: url('../../../../../assets/media/svg/check-white.svg');
			background-repeat: no-repeat;
			background-size: 10px 10px;
			background-position: center;
		}
	}

	.quota__input {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		opacity: 0;

		&:checked + .quota__label::before {
			background-color: $color-blue-focus;
			border: 1px solid $color-blue-focus;
		}
	}
}