// IBMPlexMono

@font-face {
  font-family: IBMPlexMono;
  src: url('./fonts/IBMPlexMono/IBMPlexMono-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: IBMPlexMono;
  src: url('./fonts/IBMPlexMono/IBMPlexMono-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: IBMPlexMono;
  src: url('./fonts/IBMPlexMono/IBMPlexMono-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: IBMPlexMono;
  src: url('./fonts/IBMPlexMono/IBMPlexMono-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: IBMPlexMono;
  src: url('./fonts/IBMPlexMono/IBMPlexMono-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: IBMPlexMono;
  src: url('./fonts/IBMPlexMono/IBMPlexMono-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: IBMPlexMono;
  src: url('./fonts/IBMPlexMono/IBMPlexMono-Bold.ttf');
  font-weight: 700;
}

// Commissioner

@font-face {
  font-family: Commissioner;
  src: url('./fonts/Commissioner/Commissioner-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: Commissioner;
  src: url('./fonts/Commissioner/Commissioner-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: Commissioner;
  src: url('./fonts/Commissioner/Commissioner-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: Commissioner;
  src: url('./fonts/Commissioner/Commissioner-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Commissioner;
  src: url('./fonts/Commissioner/Commissioner-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Commissioner;
  src: url('./fonts/Commissioner/Commissioner-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: Commissioner;
  src: url('./fonts/Commissioner/Commissioner-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Commissioner;
  src: url('./fonts/Commissioner/Commissioner-ExtraBold.ttf');
  font-weight: 900;
}