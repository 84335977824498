@import '../../assets/styles/mixins';
@import '../../assets/styles/colors';
// @import '../../../assets/styles/variables';

.tabs-select-wrapper {
	padding-left: 15px;
	display: flex;
	width: 100%;
	overflow-x: auto;

	.tab-selector {
		white-space: nowrap;
		background-color: transparent;
		outline: none;
		border: none;
		cursor: pointer;
		margin-right: 22px;
		padding: 9px 5px;
		border-bottom: 2px solid transparent;

		span {
			font-weight: 500;
			font-size: 14px;
			letter-spacing: 0.04em;
			color: $color-input-placeholder;
			text-transform: uppercase;
			white-space: nowrap;
		}

		&.active {
			border-bottom: 2px solid $color-blue-focus;
			span {
				color: $color-blue-focus;
			}

			.tab-bagel {
				background-color: $color-blue-focus;
			}
		}

		.tab-bagel {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-left: 10px;
			width: 19px;
			height: 19px;
			background-color: $color-input-placeholder;
			border-radius: 50%;

			.tab-count {
				font-weight: 600;
				font-size: 12px;
				color: #fff;
			}
		}
	}
}
