@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';

.points-counter {
	display: flex;
	align-items: center;

	&__icon {
		width: 20px;
		height: 20px;
		margin-left: 6px;

		& > i {
			display: block;
			height: 100%;
			width: 100%;

			@include bg-image('svg/x-point.svg', auto);
		}
	}

	&.increase {
		.points-counter__count {
			color: $color-green;
		}
	}

	&.decrease {
		.points-counter__count {
			color: $color-red;
		}
	}

	&.increase > .points-counter__icon,
	&.decrease > .points-counter__icon,
	&.white > .points-counter__icon {
		width: 16px;
		height: 16px;
	}

	&.white {
		align-items: flex-end;

		.points-counter__count {
			font-weight: 500;
			font-size: 28px;
			line-height: 32px;
			letter-spacing: 0.04em;
		}

		.points-counter__icon {
			margin-bottom: 4px;
		}
	}
}

.points-counter,
.points-counter > .points-counter__count {
	font-weight: inherit;
	letter-spacing: inherit;
}
