.comment {
	&__header {
		position: relative;
		display: flex;
		align-items: center;
		height: 56px;
		padding-left: 18px;
		padding-right: 14px;
		border-bottom: 1px solid #e3e5e7;

		.title {
			font-size: 1.5em;
			line-height: 120%;
			font-weight: 500;
		}
	}

	&__body {
		padding: 20px;

		& > .text {
			margin-bottom: 20px;
			font-size: 16px;
			line-height: 24px;
		}
	}
}
