.programm-block {
	position: relative;
	padding: 16px;
	background: #f5f7f9;
	border-radius: 8px;
	margin-top: 12px;
	border: 1px solid transparent;

	&.errors {
		border: 1px solid #e9534a;
	}

	& + .programm-block {
		margin-top: 20px;
	}

	&__inputs-block {
		& > .time-block {
			display: flex;
			width: 100%;
			justify-content: space-between;
			gap: 10px;
			align-items: center;

			& > .delete-button {
				width: 24px;
				height: 24px;
				background-color: transparent;
				border: none;
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
				background-image: url('../../../../../assets/media/svg/delete-black.svg');
				background-repeat: no-repeat;
				background-position: left center;
				outline: none;
				cursor: pointer;
			}

			& > .react-datepicker-wrapper {
				width: 49%;
			}
		}

		& > .input-main-container {
			margin-top: 8px;
		}
	}
}
