@import '../../../../../../assets/styles/mixins';

.header-button {
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;

	& + & {
		margin-left: 20px;
	}

	& > i {
		display: block;
		width: 24px;
		height: 24px;
	}

	&.prev {
		& > i {
			@include bg-image('svg/text-editor-icons/prev.svg', auto);
		}
	}

	&.next {
		& > i {
			@include bg-image('svg/text-editor-icons/next.svg', auto);
		}
	}

	&.del {
		& > i {
			@include bg-image('svg/delete-black.svg', center, contain);
		}
	}

	&.disabled {
		opacity: 0.3;
		pointer-events: none;
	}
}
