@import '../../../assets/styles/mixins';
@import '../../../assets/styles/colors';

.actual-info-block {
	& > .tiles {
		display: grid;
		grid-template-rows: repeat(2, 200px);
		grid-template-columns: repeat(2, 1fr);
		column-gap: 32px;
		row-gap: 25px;
		width: 100%;

		.tile__wrapper {
			margin-bottom: 0;

			&.change-image {
				& > .tile {
					background-repeat: no-repeat;
					background-position: right bottom;
					background-size: auto 85%;
				}

				& > .tile__blur {
					background-repeat: no-repeat;
					background-position: right bottom;
					background-size: auto 85%;
				}
			}

			&.tile-0 {
				grid-area: 1 / 1 / 2 / 1;
				width: 640px;
				height: 425px;
			}

			&.tile-1 {
				grid-area: 1 / 2 / 1 / 2;
			}

			&.tile-2 {
				grid-area: 2 / 2 / 2 / 2;
			}

			.item-options-absolute {
				opacity: 0;
			}

			&:hover {
				.item-options-absolute {
					opacity: 1;
				}
			}

			.tile__label {
				padding: 8px 15px;
			}

			.tile-subtitle.white {
				color: #fff;
			}
		}

		& > .empty-element-block {
			width: 304px;
			height: 200px;

			.empty-element-block__container {
				width: 100%;
				height: 100%;
				& > span {
					width: 100%;
					text-align: center;
				}
			}

			&.tile-0 {
				grid-area: 1 / 1 / 2 / 1;
				width: 640px;
				height: 425px;
			}

			&.tile-1 {
				grid-area: 1 / 2 / 1 / 2;
			}

			&.tile-2 {
				grid-area: 2 / 2 / 2 / 2;
			}
		}
	}
}
