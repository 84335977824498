@import '../../../assets/styles/colors';
.cancel__button {
	background-color: transparent;
	cursor: pointer;
	border: none;
	transition: 0.3s all;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	margin-right: 30px;
	outline: none;

	&:hover {
		color: $color-blue;
	}
}
