.add-group-modal {
	.add-groups-text {
		& > p {
			font-size: 20px;

			& > span {
				font-weight: 600;
				font-size: 20px;
			}
		}
	}

	.buttons-block {
		display: flex;
		justify-content: space-between;
		margin-top: 30px;
	}
}
