@import '../../../assets/styles/mixins';

.table-titles-block {
	display: grid;
	width: 100%;
	grid-auto-flow: column;
	width: 100%;
	margin-bottom: 8px;
	animation: fade 0.25s ease-out backwards;
}

.events .table-titles-block {
	grid-template-columns: 464px 130px 130px 208px 145px 135px 55px;
}

.users-table .table-titles-block {
	grid-template-columns: 64px 240px 220px 142px 136px 175px 177px 154px 120px 32px;

	@include max-width(768px) {
		grid-template-columns: 40px 240px 220px 142px 136px 354px 154px 120px 32px;
	}
}
