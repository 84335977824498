@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.questionnaire-content {
	position: relative;
	display: flex;
	flex-direction: column;
	overflow-y: auto;

	.panel-title {
		width: 100%;
		h3 {
			margin-bottom: 0;
		}
	}

	&::-webkit-scrollbar {
		width: 0px;
	}

	& > .avatar {
		margin-bottom: 35px;
	}

	&.buttons {
		height: calc(100% - 30px);
	}

	.user-form-status {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 160px;
		height: 40px;
		border-radius: 8px;

		.icon-container {
			width: 24px;
			height: 24px;
		}

		&.red {
			background-color: $color-red;
			.icon {
				@include bg-image('svg/status-icons/close-white.svg', center, auto);
			}
		}

		&.green {
			background-color: $color-green;
			.icon-container {
				width: 16px;
				height: 16px;
			}
			.icon {
				@include bg-image('svg/status-icons/check-white.svg', center, contain);
			}
		}

		&.blue {
			background-color: $color-blue-focus;
			.icon {
				@include bg-image('svg/status-icons/eye.svg', center, auto);
			}
		}

		&.black {
			background-color: $color-input-placeholder;
			.icon {
				@include bg-image('svg/status-icons/close-white.svg', center, auto);
			}
		}
		.text {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			color: #fff;
			margin-left: 9px;
		}
	}

	.form-status {
		position: fixed;
		top: 25px;
		right: 20px;
		height: 24px;
		width: 24px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;

		&.red {
			background-color: rgba($color: $color-red, $alpha: 0.15);
		}

		&.green {
			background-color: rgba($color: $color-green, $alpha: 0.15);
		}

		&.blue {
			background-color: rgba($color: $color-blue-focus, $alpha: 0.15);
		}
	}

	.pdf-button-wrapper {
		padding: 10px;
		display: flex;
		justify-content: flex-end;
	}

	@include max-width(480px) {
		.user-form-status {
			width: 40px;

			.text {
				display: none;
			}
		}
	}
}

.preloader-container {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.6);
	z-index: 1;
}

.pdf-button-wrapper {
	display: flex;
	justify-content: flex-end;
	padding: 2px 0 5px 0;
}

.analitics {
	width: 100%;
	margin-top: 20px;

	&__ref,
	&__points {
		overflow-wrap: break-word;
	}
}
