.column {
	width: 50%;

	& + .column {
		margin-left: 30px;
	}

	.panel + .panel {
		margin-top: 24px;
	}
}
