@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/mixins';

.content-item-social-line {
	align-items: center;
	justify-content: flex-start;

	&__icon {
		width: 16px;
		height: 16px;
		margin-right: 8px;

		& > i {
			display: block;
			width: 100%;
			height: 100%;
		}

		&.social_vk {
			& > i {
				@include bg-image('svg/social-icons/social_vk.svg', auto);
			}
		}

		&.social_tg {
			& > i {
				@include bg-image('svg/social-icons/social_tg.svg', auto);
			}
		}

		&.social_fb {
			& > i {
				@include bg-image('svg/social-icons/social_fb.svg', auto);
			}
		}

		&.social_inst {
			& > i {
				@include bg-image('svg/social-icons/social_inst.svg', auto);
			}
		}
		&.social_habr {
			& > i {
				@include bg-image('svg/social-icons/social_hr.svg', auto);
			}
		}
		&.social_github {
			& > i {
				@include bg-image('svg/social-icons/social_git.svg', auto);
			}
		}
		&.social_in {
			& > i {
				@include bg-image('svg/social-icons/social_in.svg', auto);
			}
		}
		&.social_behance {
			& > i {
				@include bg-image('svg/social-icons/social_be.svg', auto);
			}
		}
		&.social_dribbble {
			& > i {
				@include bg-image('svg/social-icons/social_dr.svg', auto);
			}
		}
	}

	@include max-width(480px) {
		flex-direction: row;
	}
}
