@import '../../../assets/styles/colors';

.info-page-add-gallery {
	display: flex;
	justify-content: center;
	width: 50%;

	.panel {
		width: 100%;

		.panel-title {
			margin-bottom: 16px;
		}
	}
}
