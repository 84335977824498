@import '../../../../../../../assets/styles/colors';

.user-admin-notes {
	.notes-message {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 2px;

		&__input {
			display: flex;
			flex-grow: 1;

			textarea {
				width: 100%;
				min-height: 80px;
				border-radius: 8px;
				background-color: $color-blue-ghost;
				border: none;
				outline: none;
			}
		}

		&__send-btn {
			margin-left: 15px;
		}
	}

	.send-button {
		background-color: $color-blue;
		border-radius: 50%;

		&:hover {
			background-color: $color-blue-focus;

			& {
				box-shadow: 0px 2px 2px rgba(62, 100, 200, 0.1), 0px 3px 4px rgba(62, 100, 200, 0.15),
					0px 4px 8px rgba(62, 100, 200, 0.4);
			}
		}
	}
}
