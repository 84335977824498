.view-avatar-modal {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0);
	backdrop-filter: blur(0);
	transition: all 0.25s ease-out;
	z-index: -1;
	cursor: pointer;

	& > .btn-close {
		position: fixed;
		top: 12px;
		right: 12px;
	}

	&.show {
		background: rgba(0, 0, 0, 0.5);
		backdrop-filter: blur(16px);
		z-index: 200;

		.view-avatar-modal__container {
			opacity: 1;
			transform: translateY(0);
		}
	}

	&__container {
		position: relative;
		max-width: 90%;
		opacity: 0;
		transform: translateY(16px);
		transition: opacity 0.25s ease-out, transform 0.25s ease-out;
		cursor: default;
	}

	&__image {
		width: 100%;
	}
}
