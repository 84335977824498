@import '../../../../../assets/styles/mixins';

.block-header-title {
	display: flex;
	align-items: center;

	&__icon {
		& > i {
			display: block;
			margin-right: 12px;
			width: 24px;
			height: 24px;
		}

		&--text {
			& > i {
				@include bg-image('svg/text-editor-icons/text.svg', auto);
			}
		}

		&--key {
			& > i {
				@include bg-image('svg/text-editor-icons/key-text.svg', auto);
			}
		}

		&--photo {
			& > i {
				@include bg-image('svg/text-editor-icons/images.svg', auto);
			}
		}

		&--video {
			& > i {
				@include bg-image('svg/text-editor-icons/video.svg', auto);
			}
		}
	}
}
