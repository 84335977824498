@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.choice-block {
	position: relative;
	width: 100%;
	background: $color-app-main;

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: -20px;
		height: 1px;
		width: calc(100% + 40px);
		background-color: $color-border-light;
	}

	.text-block {
		margin-top: 8px;
	}

	& > .buttons-block {
		display: flex;
		justify-content: space-between;
		position: relative;
		top: 10px;
	}

	@include max-width(480px) {
		& > .buttons-block {
			flex-direction: column;

			.button-main + .button-main {
				margin-top: 10px;
			}
		}
	}
}
