@import '../../../assets/styles/colors';

.analitics-table {
	width: 100%;

	.table-titles-block {
		grid-template-columns: 300px 300px 100px 100px;
	}

	.table-rows-block {
		.table-row {
			grid-template-columns: 300px 300px 100px 100px;

			&:nth-child(odd) {
				background-color: $color-gray-light;
			}
		}
	}

	.empty-data {
		width: 100%;
		margin-top: 15px;
		text-align: center;
		font-size: 16px;
		font-weight: 600;
	}
}
