@import '../../assets/styles/colors';

.already-deleted {
	display: flex;
	padding: 8px;
	border-radius: 6px;
	background-color: $color-rose;
}

.delete-reason {
	margin-top: 15px;
	color: $color-red;
	font-size: 16px;
	line-height: 140%;

	& > span {
		color: $color-red;
		font-weight: 600;
		font-size: 16px;
		line-height: 140%;
	}
}
