@import '../../../../assets/styles/mixins';

.right-column {
	width: 332px;
	margin-left: 32px;

	.panel + .panel {
		margin-top: 24px;
	}

	@include max-width(992px) {
		display: none;
	}
}
