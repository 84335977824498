@import '../../../../assets/styles/colors';

.questionnaire-content-item {
	padding-bottom: 18px;
	border-bottom: 1px solid $color-panel-border;
	max-height: 250px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 2px;
	}

	&:last-child {
		border-bottom: none;
	}

	&__title {
		margin-bottom: 10px;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.04em;
		color: $color-text-black;
	}

	.content-item-line + .content-item-line {
		margin-top: 12px;
	}
}
