@import '../../../../../../assets/styles/_colors';
@import '../../../../../../assets/styles/mixins';

.user-item {
	.requests-list {
		.user-admin-transactions {
			height: 100%;

			.table {
				height: calc(100% - 53px);
				justify-content: space-between;
			}

			.table-titles-block {
				grid-template-columns: 120px 620px 90px;

				gap: 20px;
			}

			.table-title-block:last-child {
				justify-content: flex-end;
			}

			.table-rows-block {
				flex-grow: 1;
			}

			.table-row {
				grid-template-columns: 120px 620px 90px;
				gap: 20px;
				padding: 2px 0;

				&:nth-child(odd) {
					background-color: $color-gray-light;
					width: 100%;
					border-radius: 8px;
				}

				.trans-value {
					display: flex;
					align-items: center;
					width: 100%;
					justify-content: flex-end;

					&.positive {
						color: $color-green;
					}

					&.negative {
						color: $color-red;
					}
				}

				.icon-wrapper {
					width: 12px;
					height: 12px;
					margin-left: 8px;
					i {
						display: block;
						width: 100%;
						height: 100%;
						@include bg-image('svg/x-point.svg', center);
					}
				}
			}
		}
	}
}
