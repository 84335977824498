.products {
	.page-wrapper {
		.columns-container {
			.right-column > .button-main {
				width: 100%;
				padding: 16px 40px;
				margin-bottom: 16px;
				box-shadow: 0px 2px 4px rgba(62, 100, 200, 0.1), 0px 3px 8px rgba(62, 100, 200, 0.15);
			}
		}
	}
}