@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.light-button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 18px;
	width: 100%;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.04em;
	color: $color-blue-focus;
	background-color: $color-blue-ghost;
	border: none;
	border-radius: 8px;
	outline: none;
	cursor: pointer;

	&.red {
		background-color: $color-red-ghost;

		&__text {
			color: $color-red;
		}
	}
}
