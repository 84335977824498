.tile-subtitle {
	font-size: 12px;
	line-height: 16px;

	&.medium {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
	}

	&.large {
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.04em;
	}
}
