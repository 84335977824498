@import './colors';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: Commissioner, sans-serif;
	font-weight: 400;
	color: $color-text-black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;

	&::-webkit-scrollbar {
		/* 1 - скроллбар */
		width: 6px;
		height: 6px;
		background-color: #ffffff;
	}

	&::-webkit-scrollbar-thumb {
		/* 5 - ползунок */
		border-radius: 2px;
		background-color: #d1d1d1;
	}
}

a {
	text-decoration: none;
	color: $color-text-black;
	&:hover {
		color: $color-text-black;
	}
}

p {
	margin: 0;
}
