.event-format-block {
	margin-top: 15px;
	margin-bottom: 12px;

	&__buttons {
		display: flex;
		flex-wrap: wrap;
		margin-top: 6px;

		& > button {
			margin-right: 10px;
		}
	}
}
