.users-filters {
	height: 100%;
	overflow: auto;
	display: flex;
	flex-direction: column;

	.users-period {
		margin-top: 22px;
		.input-main-container {
			input {
				height: 35px;
			}
			input:focus,
			input:not([value='']) {
				height: 45px;
				padding: 20px 12px 8px;
			}
		}
	}

	.panel {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 20px 0;

		& > .filters-header {
			padding: 0 20px;
		}
	}

	.users-filters-wrapper {
		height: 100%;
		padding: 0 20px;
		overflow-y: auto;
	}

	.filters {
		.filters-body {
			& > div:not(:first-child) {
				margin-top: 22px;
			}
		}
	}

	.filters:not(:first-child) {
		margin-top: 22px;
	}

	.filters:last-child {
		margin-top: 24px;

		.filters-body {
			.filter {
				margin-top: 10px;
			}
		}
	}
}
