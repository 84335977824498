.user-admin-groups {
	min-height: 220px;

	.user-groups-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 8px;

		.add-button-wrapper {
			.add-button {
				border: none;
				outline: none;
				cursor: pointer;

				.icon {
					margin: 0;
				}

				.add-text {
					margin-left: 10px;
				}
			}
		}

		.add-groups-list-item.empty {
			padding: 6px 20px;
			pointer-events: none;
		}
	}
}
