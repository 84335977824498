.delete-comment-block {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	&__header {
		display: flex;
		margin-bottom: 20px;

		& > .sender-avatar {
			width: 58px;
			min-width: 58px;
			height: 58px;
			margin-right: 12px;

			& > img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 50%;
			}
		}

		& > .attention-text {
			& > p {
				font-size: 16px;
				line-height: 24px;
				& > span {
					font-weight: 600;
				}
			}
		}
	}

	& > .button-block {
		display: flex;
		width: 100%;
		justify-content: flex-end;
		margin-top: 20px;
	}
}
