@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/colors';

.product-item__page-header {
	display: flex;
	justify-content: space-between;
	width: 100%;
	position: fixed;
	background-color: $color-app-background;
	padding: 10px 40px 10px 16px;
	z-index: 10;

	.product-header__buttoms {
		display: flex;
	}
}
