@import '../../../../assets/styles/colors';

.group__table {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	.panel {
		width: 100%;
		padding: 0;

		.group__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px 20px 0 20px;

			&>.button-main {
				margin-top: 0;

				.bg {
					background-color: $color-group-bg;
				}

				.label {
					color: $color-text-black;
					font-weight: 400;
				}

				&:hover {
					.bg {
						background-color: #e5ebf8;
					}
				}
			}
		}
	}

	& > .button-main {
		margin-top: 16px;
	}

	& > .modal-wrapper.profile {
		.modal-container {
			padding: 0;
			max-width: 950px;
			min-height: 500px;

			.btn-close {
				top: 0;
			}
		}
	}
}