@import '../../../../assets/styles/colors';

.quota__wrapper {
	.quota__content {
		padding: 25px 38px;

		.quota__title {
			font-weight: 500;
			font-size: 28px;
			line-height: 32px;
			margin-bottom: 27px;
		}

		.search-input-container {
			max-width: 440px;
		}

		.quota__content-box {
			margin-top: 25px;
		}
	}

	.quota__buttons {
		padding: 20px;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.button-main {
			margin-top: 0;
		}
	}

	.quota-checkbox-block {
		background-color: #fafafa;
		padding: 10px 10px 10px 40px;
		position: relative;
		box-shadow: inset 0 0 2px -3px rgba(0, 0, 0, 0.15);
		border-bottom: 2px solid rgba(77, 77, 77, 0.1);
	}
}
