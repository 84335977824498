.group__row {
	padding: 15px 20px;
	& > .modal-wrapper.show {
		z-index: 101;
	}

	.table {
		border: none;
	}

	.table-rows-block {
		.group__row-item {
			display: flex;
			align-items: center;

			.delete__button {
				max-width: 55px;
				width: 100%;
			}
		}

		.group {
			grid-template-columns: 32px 6fr 2fr 1fr;
			padding: 8px 12px;

			.table-request-row__image {
				width: 32px;
				height: 32px;
				border-radius: 50%;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
			}

			.table-request-row__name {
				margin-left: 12px;
				font-weight: normal;
				font-size: 16px;
				line-height: 100%;
			}

			.table-request-row__phone {
				font-weight: normal;
				font-size: 16px;
				line-height: 100%;
				margin-right: 16px;
			}

			.table-request-row__reject-button,
			.table-request-row__accept-button {
				display: none;
			}
		}
	}
}
