@import '../../../../../assets/styles/mixins';

.add-video__wrapper {
	height: 100%;
	max-height: 815px;
	display: flex;
	flex-direction: column;

	.add-video-input {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 15px;

		.light-button {
			max-width: 40%;
		}
	}

	.input-main-container {
		max-width: 55%;
	}

	.video-prewiew__wrapper {
		display: flex;

		.video-prewiew {
			display: flex;
			flex-wrap: wrap;
			gap: 16px;
			pointer-events: none;
		}
	}

	.prewiew-wrapper {
		position: relative;
		display: inline-block;

		.btn-wrapper {
			pointer-events: auto;
		}
	}

	@include max-width(480px) {
		.add-video-input {
			.light-button {
				max-width: 100%;
				margin-top: 10px;
			}
		}

		.input-main-container {
			max-width: 100%;
		}
	}
}
