@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.select-input {
	position: relative;

	&__text {
		width: 100%;
		height: 48px;
		padding: 13px 16px;
		background-color: $color-app-main;
		border: 1px solid $color-border;
		outline: none;
		font-size: 16px;
		transition: $transition-main;
		cursor: pointer;

		&::placeholder {
			color: $color-input-placeholder;
		}

		&:disabled {
			color: #959595;
		}

		&:not([value='']) {
			padding-top: 20px;
			padding-bottom: 7px;

			& + label {
				top: 5px;
				font-size: 12px;
			}
		}

		&:focus {
			caret-color: transparent;
		}

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	&__label {
		position: absolute;
		top: 14px;
		left: 17px;
		color: $color-input-placeholder;
		transition: $transition-main;
		cursor: pointer;
	}
}
