@import '../../../../assets/styles/colors';

.event-info-block {
	.modal-wrapper.map {
		.modal-container {
			padding: 0;
			max-width: 900px;

			.btn-close {
				top: 0;
				right: -40px;
				filter: invert(1);
			}
		}
	}

	.location__button {
		background-color: transparent;
		outline: none;
		border: none;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: $color-blue-focus;
		cursor: pointer;
		transition: 0.3s all;
	}

	&__stream-switch {
		margin-bottom: 8px;
	}
}

.date-period__block {
	display: flex;
	justify-content: space-between;

	& > .date-picker-block {
		width: 49%;

		& > .react-datepicker-wrapper {
			width: 100%;
		}
	}
}

.event-info-block.past-event,
.event-info-block.delete-event {
	pointer-events: none;

	.event-date-block,
	.load__image,
	.event-format-block,
	.event-description-block {
		opacity: 0.6;
	}

	.load__image-load {
		display: none;
	}
}
