@import '../../../assets/styles/colors';

.main-news-block {
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: $color-blue-focus;

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		max-width: 976px;
		width: 100%;
		margin: 20px 0 17px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		width: 100%;

		& > h3 {
			color: $color-white;
			font-weight: 500;
			font-size: 28px;
			line-height: 32px;
		}
	}

	&__body {
		width: 100%;

		&-input {
			width: 100%;

			& > .input-main-container {
				max-width: 100%;
			}
		}
	}
}
