.event-stream-block {
	.time-block {
		margin-top: 6px;
		width: 100%;

		& > .react-datepicker-wrapper {
			width: 100%;
		}

		.react-datepicker__triangle {
			left: 40px !important;
		}
	}
}
