@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';

.table-certificates-row {
	display: flex;
	width: 100%;
	margin-bottom: 8px;
	cursor: pointer;

	&.user-page-certificates {
		height: 72px;

		.table-certificates-row__info {
			padding: 4px;
			grid-template-columns: 64px 400px 220px;

			.table-certificates-row__image {
				.image-plug {
					border-radius: 4px;
					height: 100%;
					width: 100%;
				}

				img {
					border-radius: 4px;
					height: 100%;
					width: 100%;
				}
			}
		}

		.table-certificates-row__status {
			width: 120px;
		}
	}

	&__image {
		width: 32px;
		height: 32px;
		display: flex;

		& > img {
			width: 32px;
			height: 32px;
			object-fit: cover;
			border-radius: 50%;
		}

		& > .image-plug {
			width: 32px;
			height: 32px;
			object-fit: cover;
			border-radius: 50%;
			@include bg-image('svg/nooffice.svg', center, contain);
		}
	}

	&__info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;
		gap: 15px;
		padding: 8px 16px;
		border-radius: 4px 0 0 4px;
		background-color: $color-gray-light;
		align-items: center;
	}

	&__top,
	&__bottom {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	&__name {
		font-weight: normal;
		font-size: 16px;
		line-height: 100%;
	}

	&__change-buttons {
		display: flex;
		align-items: center;

		.light-button + .light-button {
			margin-left: 10px;
		}
	}

	.table-certificates-row__code {
		font-weight: normal;
		font-size: 16px;
		line-height: 100%;
		span {
			color: $color-input-placeholder;
			margin-right: 9px;
		}
	}

	.table-certificates-row__status {
		display: flex;
		align-items: center;
		margin-left: 30px;

		span {
			font-weight: 500;
			font-size: 14px;
			color: $color-input-placeholder;
		}

		&.active span {
			color: $color-blue;
		}
	}
}
