.office__location {
	.office__location-wrapper {
		margin-bottom: 30px;
	}
	.input-main-container {
		&:not(:last-child) {
			margin-bottom: 8px;
		}
		input {
			background-color: #FAFAFA;
			border: 1px solid rgba(55, 59, 79, 0.24);
		}
	}
}