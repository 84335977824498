@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/mixins';

.content-item-skill-line {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 320px;
	color: $color-text-black;

	&__label {
		width: 180px;
		font-size: 16px;
		line-height: 24px;
		opacity: 1;
	}

	&__value {
		padding: 2px 8px;
		font-weight: 500;
		font-size: 12px;
		line-height: 130%;
		color: $color-app-main;
		text-transform: uppercase;
		border-radius: 4px;

		&.noob {
			background-color: $color-skill-noob;
		}

		&.Junior {
			background-color: $color-skill-junior;
		}

		&.Middle {
			background-color: $color-skill-middle;
		}

		&.Senior {
			background-color: $color-skill-senior;
		}
	}

	@include max-width(480px) {
		align-items: flex-start;
	}
}
