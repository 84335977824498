.office__info {
	.input-main-container {
		input {
			background-color: #fafafa;
			border: 1px solid rgba(55, 59, 79, 0.24);
		}
	}

	&-wrapper {
		.input-main-container:first-child {
			margin-bottom: 8px;
		}
	}

	.office__info-box {
		display: flex;
		justify-content: space-between;
		margin-top: 8px;

		.office__time-wrapper {
			display: flex;
			margin-right: 10px;

			& + .info-num {
				max-width: calc(100% / 3);
			}
		}

		.office__time-item {
			width: calc(100% / 2);
			&:first-child {
				margin-right: 10px;
			}
		}
	}

	.office__info-facilities {
		margin-top: 32px;
	}

	.office__info-buttons {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
}
