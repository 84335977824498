.bot-icon-container {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-right: 15px;

	&__link {
		display: flex;
		width: 100%;
		gap: 10px;
	}

	&__icon {
		width: 22px;
		height: 22px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-repeat: no-repeat;
	}
}
