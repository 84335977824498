.product-item {
	&__common-wrapper {
		width: 100vw;
		height: 100vh;
		position: fixed;
		z-index: 1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&__common-search {
		position: relative;
		z-index: 1;
		max-width: 420px;
		width: 100%;

		.input-main-container {
			width: 100%;
			max-width: 420px;
		}

		.city__modal {
			position: absolute;
			background-color: #ffffff;
			border: 1px solid #d9d8dc;
			z-index: 1;
			width: 100%;
			display: flex;
			flex-direction: column;

			&-item {
				background-color: #ffffff;
				border: 0;
				text-align: left;
				font-size: 16px;
				padding: 8px;
				cursor: pointer;

				&:focus {
					outline: none;
					background-color: #426edf;
					color: #ffffff;
				}

				&:hover {
					background-color: #426edf;
					color: #ffffff;
				}
			}
		}
	}
}