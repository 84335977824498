.buttons-panel-block {
	position: absolute;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	top: 40px;
	padding: 15px;
	z-index: -1;
	left: 0;
	opacity: 0;
	background-color: #f4f4f4;
	visibility: hidden;
	transition: all 0.3s ease-in-out;
	-webkit-box-shadow: 0 6px 8px 0 rgba(34, 60, 80, 0.2);
	-moz-box-shadow: 0 6px 8px 0 rgba(34, 60, 80, 0.2);
	box-shadow: 0 6px 8px 0 rgba(34, 60, 80, 0.2);

	&.show {
		opacity: 1;
		visibility: visible;
		z-index: 1;
	}
}
