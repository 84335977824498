@import '../../../../../../assets/styles/colors';

.popup__map {
	max-width: 900px;
	width: 100%;
	// height: 720px;
	border: 1px solid rgba(48, 50, 59, 0.15);
	border-radius: 8px;
	overflow: hidden;
	&-item {
		height: 650px;
	}

	&-description {
		display: flex;
		justify-content: space-between;
		padding: 12px 30px;
		background-color: #ffffff;
		min-height: 65px;
	}

	&-box {
		display: flex;
	}

	&-buttons {
		display: flex;
		align-items: center;

		.button-main {
			padding: 9px 36px;
			line-height: 0;
			min-height: 44px;
		}
	}
}
