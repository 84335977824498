.social-block-container {
	padding-bottom: 60px;

	&__header {
		width: 100%;
		margin-bottom: 32px;

		& > h3 {
			font-weight: 500;
			font-size: 28px;
			line-height: 32px;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		width: 100%;

		& > .social-block-input {
			display: flex;
			align-items: center;
			margin-bottom: 8px;
			width: 100%;

			.social-block-input__image {
				display: flex;
				width: 120px;
				margin-right: 30px;

				& > p {
					margin-left: 10px;
					font-size: 16px;
					line-height: 24px;
				}
			}

			.social-block-input__value {
				width: calc(100% - 150px);

				& > .input-main-container {
					max-width: 100%;
				}
			}
		}
	}
}
