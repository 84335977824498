.transactions-page{
    .button-main{
        width: 100%;
        margin-bottom: 16px;
    }

    .modal-wrapper.profile .modal-container{
        max-height: 380px;
        max-width: 400px;
        min-height: auto;
    }
}