.button-icon-main {
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}
