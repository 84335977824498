@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.info-page-tag {
	position: relative;
	padding: 8px 20px;
	background: $color-gray-light;
	border-radius: 8px;
	transition: $transition-main;
	cursor: pointer;

	&__overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		border-radius: 8px;
		background-color: $color-blue-ghost;
		opacity: 0;

		& > i {
			display: block;
			width: 24px;
			height: 24px;

			@include bg-image('svg/delete-black.svg', center, contain);
		}
	}

	&:hover {
		color: rgba($color: #000000, $alpha: 0.2);

		.info-page-tag__overlay {
			opacity: 1;
		}
	}
}
