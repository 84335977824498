@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';

.table-request-row {
	display: grid;
	grid-template-columns: 45px 200px 145px 120px repeat(2, 1fr);
	align-items: center;
	padding: 8px 16px;
	border-radius: 8px;
	background-color: $color-gray-light;
	margin-bottom: 10px;

	&.user-page-requests {
		height: 72px;
		padding: 4px;
		grid-template-columns: 64px 366px 20px repeat(2, 1fr);

		.table-request-row__questionnaire-button {
			display: none;
		}

		.table-request-row__image {
			height: 100%;
			width: 100%;

			.image-plug {
				border-radius: 4px;
				height: 100%;
				width: 100%;
			}

			img {
				border-radius: 4px;
				height: 100%;
				width: 100%;
			}
		}

		.table-request-row__product-info {
			margin-left: 16px;

			.table-request-row__name {
				font-weight: 500;
				font-size: 20px;
			}

			a {
				font-size: 14px;
				color: $color-blue;
				&:hover {
					color: $color-blue-focus;
				}
			}
		}
	}

	&__name,
	&__phone {
		font-weight: normal;
		font-size: 16px;
		line-height: 100%;
	}

	& > .table-request-row__image {
		width: 32px;
		height: 32px;

		& > img {
			width: 32px;
			height: 32px;
			object-fit: cover;
			border-radius: 50%;
		}

		& > .image-plug {
			width: 32px;
			height: 32px;
			object-fit: cover;
			border-radius: 50%;
			@include bg-image('svg/nooffice.svg', center, contain);
		}
	}

	& + .table-request-row {
		margin-top: 5px;
	}

	&__questionnaire-button {
		display: flex;
		justify-content: flex-end;
	}

	&__questionnaire-button,
	&__reject-button,
	&__accept-button {
		display: flex;
		justify-content: flex-end;
	}

	& > span {
		margin-left: 20px;
	}

	& > .approve {
		color: $color-green;
	}

	& .reject {
		color: $color-red;
	}
}
