.news-block-container {
	width: 100%;
	display: flex;

	&__news {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 32px;
		border-radius: 8px;
		background-color: #fff;
	}
}
