@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.filters {
	&-header {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 38px;

		&__title {
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.04em;

			&::after {
				content: '';
				position: absolute;
				width: 100%;
				height: 1px;
				bottom: -10px;
				left: 0;
				background-color: $color-border-light;
			}
		}

		&__reset {
			display: flex;
			align-items: center;
			padding: 6px 12px;
			font-size: 14px;
			line-height: 20px;
			border-radius: 20px;
			cursor: pointer;

			&:hover {
				background-color: $color-blue-ghost;
			}

			i {
				display: block;
				margin-right: 4px;
				width: 24px;
				height: 24px;

				@include bg-image('svg/clear.svg', center);
			}
		}
	}

	&-body {
		display: flex;
		flex-direction: column;
		& > div:not(:first-child) {
			margin-top: 22px;
		}
	}
}
