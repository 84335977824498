.confirm-modal {
	&__text {
		margin-bottom: 40px;
		text-align: center;
		font-size: 16px;
		font-weight: 500;
	}

	&__buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
