@import '../../../assets/styles/colors';

.info-page-types-btns {
	display: flex;

	button {
		margin-left: 4px;

		&:disabled {
			& > .bg {
				background-color: $color-blue-focus;
			}
		}
	}
}
