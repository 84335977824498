@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';

.tile__wrapper {
	position: relative;
	margin-bottom: 16px;

	&:hover {
		.item-options-absolute {
			z-index: 10;
		}
	}

	& > .delete-button {
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
		z-index: 10;
	}
}

.tile {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-size: cover;
	background-position: right;
	padding: 12px;
	border-radius: 8px;
	width: 100%;
	height: 100%;
	z-index: 2;

	&.purple {
		background-color: #96408d;
		box-shadow: 0px 7px 8px 0px rgba(150, 64, 141, 0.6);
		border: 0;
		.points-counter__count {
			color: #ffffff;
		}

		.points-counter__icon {
			i {
				@include bg-image('svg/x-point-white.svg', auto);
			}
		}

		.tile-subtitle {
			color: #ffffff;
		}
	}

	&.blue {
		background-color: #567bd7;
		box-shadow: 0px 7px 8px 0px rgba(86, 123, 215, 0.6);
		border: 0;
		.points-counter__count {
			color: #ffffff;
		}

		.points-counter__icon {
			i {
				@include bg-image('svg/x-point-white.svg', auto);
			}
		}

		.tile-subtitle {
			color: #ffffff;
		}
	}

	&.red {
		background-color: #cc2c3f;
		box-shadow: 0px 7px 8px 0px rgba(204, 44, 63, 0.6);
		border: 0;
		.points-counter__count {
			color: #ffffff;
		}

		.points-counter__icon {
			i {
				@include bg-image('svg/x-point-white.svg', auto);
			}
		}

		.tile-subtitle {
			color: #ffffff;
		}
	}

	&__label {
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 16px 30px;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.04em;
		color: $color-app-main;
		border-top-right-radius: 8px;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	&__sublabel {
		font-weight: inherit;
		font-size: inherit;
		line-height: inherit;
		letter-spacing: inherit;
		color: inherit;
		opacity: 0.6;
	}

	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #0009;
		border-radius: 8px;
		opacity: 0;
		transition: $transition-main;

		&-text {
			padding: 12px 28px;
			font-weight: 600;
			line-height: 24px;
			letter-spacing: 0.04em;
			background-color: $color-app-main;
			border-radius: 8px;
		}
	}

	&__blur {
		position: absolute;
		width: 95%;
		height: 95%;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		background-size: cover;
		background-position: center;
		border-radius: 12px;
		filter: blur(16px);
		z-index: 1;
	}

	&:hover > .tile__link {
		opacity: 1;
	}

	&.small {
		.tile__label {
			padding: 6px 12px;
			font-size: 14px;
			line-height: 20px;
		}
	}

	&.hideTitle {
		.tile-title {
			display: none;
		}
	}
}
