@import '../../../../assets//styles/colors';

.office__table {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	.tabs-select-wrapper {
		width: 100%;
	}

	.panel {
		width: 100%;
	}

	.table {
		border: none;
		padding: 0;
	}

	.table-request-row {
		&.office {
			grid-template-columns: 32px 1fr 160px 120px repeat(2, 160px);
			align-items: center;
		}

		&__image {
			border-radius: 50%;
			width: 32px;
			height: 32px;
			background-size: contain;
			background-repeat: no-repeat;
		}

		&__name,
		&__phone {
			font-weight: normal;
			font-size: 16px;
			line-height: 100%;
		}
	}

	&>.button-main {
		margin-top: 16px;
	}
}