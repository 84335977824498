@import '../../../../../../../assets/styles/_colors';
@import '../../../../../../../assets/styles/mixins';

.user-transactions-header {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 5px;
	margin-top: -15px;
	&::after {
		content: '';
		position: absolute;
		height: 1px;
		width: calc(100% + 25px);
		bottom: 0;
		left: -20px;
		background-color: $color-border;
	}

	.user-money {
		display: flex;
		align-items: center;
		& > span {
			display: flex;
			align-items: center;
			height: 20px;
			font-weight: 500;
			font-size: 20px;
		}
		.icon-wrapper {
			display: flex;
			align-items: flex-end;
			width: 20px;
			height: 20px;
			margin-left: 8px;
			i {
				display: block;
				width: 16px;
				height: 16px;
				@include bg-image('svg/x-point.svg', center);
			}
		}
	}

	.add-money-button {
		.add-button {
			padding: 12px 40px;
			border-radius: 8px;
			display: flex;
			border: none;
			outline: none;
			cursor: pointer;
			.icon {
				margin: 0;
			}
			.add-text {
				margin-left: 10px;
			}
		}
	}
}
