@import '../../assets/styles/mixins';

.icon {
	display: block;
	height: 100%;
	width: 100%;

	&.certificates {
		@include bg-image('svg/certificates.svg', center, auto);
	}

	&.delete-black {
		@include bg-image('svg/delete-black.svg', center, auto);
	}

	&.plus {
		@include bg-image('svg/plus.svg', center, auto);
	}

	&.notifications {
		@include bg-image('svg/notifications.svg', center, auto);
	}

	&.approved {
		@include bg-image('svg/approved.svg', center, auto);
	}

	&.denied {
		@include bg-image('svg/denied.svg', center, auto);
	}

	&.checkmark-white {
		@include bg-image('svg/checkmark-white.svg', center, auto);
	}

	&.close {
		@include bg-image('svg/close.svg', center, auto);
	}

	&.close-white {
		@include bg-image('svg/status-icons/close-white.svg', center, auto);
	}

	&.dropdown {
		@include bg-image('svg/dropdown.svg', center, auto);
	}

	&.dropdown-close {
		@include bg-image('svg/dropdown-close.svg', center, auto);
	}

	&.info {
		@include bg-image('svg/info.svg', center, auto);
	}

	&.social_vk {
		@include bg-image('svg/social-icons/social_vk.svg', center, auto);
	}

	&.social_fb {
		@include bg-image('svg/social-icons/social_fb.svg', center, auto);
	}

	&.social_tg {
		@include bg-image('svg/social-icons/social_tg.svg', center, auto);
	}

	&.social_yt {
		@include bg-image('svg/social-icons/social_yt.svg', center, auto);
	}

	&.social_inst {
		@include bg-image('svg/social-icons/social_inst.svg', center, auto);
	}

	&.social_in {
		@include bg-image('svg/social-icons/social_in.svg', center, auto);
	}

	&.social_web {
		@include bg-image('svg/social-icons/social_web.svg', center, auto);
	}

	&.actived {
		@include bg-image('svg/actived.svg', center, auto);
	}

	&.accordion-arrow {
		@include bg-image('svg/accordion-arrow.svg', center, auto);
	}

	&.search {
		@include bg-image('svg/search.svg', center, auto);
	}

	&.refresh {
		@include bg-image('svg/refresh.svg', center, auto);
	}

	&.lock {
		@include bg-image('svg/lock.svg', center, auto);
	}

	&.lock-red {
		@include bg-image('svg/lock-red.svg', center, auto);
	}

	&.lock-open {
		@include bg-image('svg/lock-open.svg', center, auto);
	}

	&.x-mark {
		@include bg-image('svg/x-mark.svg', center, auto);
	}

	&.x-mark-red {
		@include bg-image('svg/x-mark-red.svg', center, auto);
	}

	&.moderate {
		@include bg-image('svg/moderate.svg', center, auto);
	}

	&.avatar-photo {
		@include bg-image('svg/avatar-photo.svg', center, auto);
	}

	&.chevron {
		@include bg-image('svg/chevron.svg', center, auto);
	}

	&.point {
		@include bg-image('svg/x-point.svg', auto);
	}

	&.contain {
		background-size: contain;
	}
}
