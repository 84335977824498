@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.modal-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0);
	backdrop-filter: blur(0);
	transition: all 0.25s ease-out;
	z-index: -1;

	&.show {
		background: rgba(0, 0, 0, 0.5);
		backdrop-filter: blur(16px);
		z-index: 100;

		.modal-container {
			opacity: 1;
			transform: translateY(0);
		}
	}

	&.profile .modal-container {
		position: relative;
		min-height: 300px;
		max-width: 640px;
		padding: 20px;

		.btn-close {
			top: 0;
			right: -40px;
			filter: invert(0.8);

			&:hover {
				filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(177deg) brightness(104%)
					contrast(104%);
			}
		}
	}

	.modal-container {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		position: relative;
		width: 90%;
		max-width: 450px;
		max-height: 90%;
		padding: 40px;
		background-color: $color-app-main;
		border-radius: 8px;
		opacity: 0;
		transform: translateY(16px);
		transition: all 0.25s ease-out;

		&::-webkit-scrollbar {
			width: 0;
		}

		.btn-close {
			position: absolute;
			top: 12px;
			right: 12px;
		}
	}

	@include max-width(992px) {
		.modal-container {
			padding: 30px 20px 15px;
			overflow: auto;

			.btn-close {
				display: none;
			}
		}
	}
}
