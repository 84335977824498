.groups__list {
	&-wrapper {
		max-width: calc(100%/1.5);
	}

	.groups {
		.table-titles-block {
			grid-template-columns: 2fr 5fr 2fr;
		}

		.table-row {
			grid-template-columns: 2fr 5fr 2fr;
			grid-auto-flow: row;
			padding: 14px 0;
		}
	}

	.page-wrapper .columns-container .right-column>.button-main {
		width: 100%;
		box-shadow: 0px 2px 4px rgba(62, 100, 200, 0.1), 0px 3px 8px rgba(62, 100, 200, 0.15);
	}
}