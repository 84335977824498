@import '../../assets/styles/mixins';

.events-main-page {
	display: flex;

	.event-page {
		display: flex;
		flex-wrap: wrap;

		& > .page-wrapper {
			&:last-child {
				height: 100%;
			}
		}
	}

	.event-list {
		width: 100%;

		& > .page-wrapper {
			padding: 16px 28px;
		}

		.right-column .button-main {
			width: 100%;
			margin-bottom: 16px;
			box-shadow: 0px 2px 4px rgba(62, 100, 200, 0.1), 0px 3px 8px rgba(62, 100, 200, 0.15);
		}

		.left-column {
			.header-mobile-block {
				display: flex;
				justify-content: space-between;
				margin-bottom: 20px;
			}
		}
	}

	@include max-width(768px) {
		.event-list {
			.page-wrapper {
				padding: 16px 13px;
			}
		}

		.left-column {
			& > .table-header-component {
				flex-wrap: wrap;
				height: auto;

				.title {
					width: 100%;
					padding: 15px 0;
				}

				.header-tools {
					margin-bottom: 15px;
				}
			}

			& > .table-header-component {
				padding-left: 9px;
				padding-right: 7px;
			}
		}
	}

	@include max-width(480px) {
		.left-column {
			.header-mobile-block {
				& > button {
					padding: 9px 15px;
					& > .label {
						font-size: 14px;
					}
				}
			}
		}
	}
}
