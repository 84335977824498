@import '../../../../assets/styles/colors';

.event-programm-block {
	position: relative;
	& > .tabs-select-wrapper {
		padding-left: 0;
	}

	& > .daily-programm-block {
		display: none;
		border-top: 1px solid $color-border;
		min-height: 100px;
		height: 100%;
		max-height: 610px;
		padding-bottom: 70px;
		overflow-y: scroll;

		&.visible {
			display: block;
		}

		&::-webkit-scrollbar {
			width: 0px;
		}
	}

	& > .add-programm-block {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 56px;
		padding: 12px 0 0;
		background-color: $color-app-main;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: -20px;
			display: block;
			width: calc(100% + 40px);
			border-top: 1px solid $color-border;
		}

		& > .add-programm-button {
			display: flex;
			height: 100%;

			& > button {
				width: 100%;
				height: 100%;
				background: #f5f7f9;
				border-radius: 8px;
				border: 1px solid transparent;
				outline: none;
				cursor: pointer;

				& > .label {
					position: relative;
					&:before,
					&:after {
						content: '';
						position: absolute;
						background-color: #4d5063;
						transition: all 0.15s ease-in-out;
						top: 50%;
					}

					&:before {
						width: 16px;
						height: 2px;
						left: -23px;
					}

					&:after {
						height: 16px;
						width: 2px;
						left: -16px;
						margin-top: -7px;
					}
				}
			}
		}
	}

	&.past-event,
	&.delete-event {
		.daily-programm-block {
			opacity: 0.6;
		}

		.add-programm-block {
			display: none;
		}

		&:after {
			position: absolute;
			top: 100px;
			display: block;
			width: 100%;
			height: calc(100% - 100px);
			z-index: 2;
			content: '';
		}
	}
}
