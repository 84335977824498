.products-block-container {
	&__list {
		--count: 1;
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		justify-content: space-between;

		& > .empty-element-block {
			.empty-element-block__container {
				width: 304px;
				height: 221px;
			}

			&.second-row {
				width: calc(100% / var(--count) - 10px * (var(--count) - 1));

				.empty-element-block__container {
					width: 100%;
					height: 221px;
				}
			}
		}

		& > .tile__wrapper {
			width: 304px;
			height: 221px;

			& > .tile {
				background-repeat: no-repeat;
				background-position: right bottom;
				background-size: auto 85%;
			}
			&.second-row {
				width: calc(100% / var(--count) - 10px * (var(--count) - 1));

				.empty-element-block__container {
					width: 100%;
					height: 221px;
				}
			}
		}
	}
}
