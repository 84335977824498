@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.users-table {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	background-color: $color-app-main;
}
