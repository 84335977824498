@import '../../../../../../assets/styles/colors';

.user-admin-notes {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	max-height: 100%;

	.notes-list {
		padding-right: 15px;
		flex-grow: 1;
		overflow-y: auto;
	}
}
