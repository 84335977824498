@import '../../../../../assets/styles/colors';

.office__facilities {
	width: 100px;
	height: 64px;
	border-radius: 8px;
	border: none;
	outline: none;
	transition: 0.3s all;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	&:not(:last-child) {
		margin-right: 8px;
	}

	span {
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		transition: 0.3s all;
	}

	&.gray {
		background-color: $color-gray-light;
		transition: 0.3s all;
	}

	&.blue {
		background-color: $color-blue-focus;
		transition: 0.3s all;
		span {
			color: $color-app-main;
		}
	}

	i {
		background-repeat: no-repeat;
		background-position: left center;
		background-size: contain;
		width: 15px;
		height: 15px;
		margin-bottom: 5px;
		transition: 0.3s all;
	}
}
