@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';

.select-input-dropdown {
	position: absolute;
	z-index: 1;
	top: 38px;
	width: 100%;
	max-height: 241px;
	list-style-type: none;
	background-color: $color-app-main;
	border: 1px solid $color-border;
	border-top: none;
	overflow-y: scroll;
	visibility: hidden;
	opacity: 0;
	transition: $transition-main;

	&.show {
		top: 48px;
		visibility: visible;
		opacity: 1;
	}

	&.without-scroll {
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			width: 0;
		}
	}

	&__item {
		padding: 13px 16px;
		cursor: pointer;

		&:hover {
			background-color: $color-app-background;
		}
	}
}
