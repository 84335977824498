@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.table {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	padding: 12px 18px;
	background-color: $color-app-main;
	border: 1px solid $color-border-light;
	border-top: none;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;

	& > .preloader-container {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(255, 255, 255, 0.6);
		z-index: 1;
	}

	.table-inner-block {
		display: flex;
		flex-wrap: wrap;
		overflow-x: auto;
		overflow-y: hidden;
	}

	@include max-width(768px) {
		padding: 12px 9px;
	}
}
