@import '../../../../../../../assets/styles/colors';

.user-groups-item {
	display: flex;
	padding: 0 20px;
	height: 48px;
	justify-content: space-between;
	align-items: center;
	border-radius: 8px;
	background-color: $color-gray-light;
	margin: 0 8px 8px 0;
	transition: all 0.15s ease-in-out;
	animation: fade 0.25s ease-out backwards;

	.group-name {
		& > span {
			font-size: 16px;
			line-height: 24px;
		}
	}

	.icon {
		margin-left: 8px;
	}
}
