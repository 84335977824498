@import '../../../assets/styles/colors';

.offices__list {
	.offices {
		.table-titles-block {
			grid-template-columns: 320px 200px 200px 200px 190px 120px 70px;
			.table-title-block:last-child {
				justify-content: center;
			}
		}

		.table-rows-block {
			.table-row {
				grid-template-columns: 70px 250px 200px 200px 200px 190px 120px 70px;

				.row-item {
					&:nth-child(6) {
						flex-direction: column;
						align-items: flex-start;
						justify-content: center;
					}

					.equipment {
						background-repeat: no-repeat;
						background-position: left center;
						padding-left: 25px;

						&:not(:last-child) {
							margin-right: 25px;
						}
					}

					&:last-child {
						justify-content: center;
					}
				}
			}
		}
	}

	.right-column {
		.button-main {
			width: 100%;
			margin-bottom: 16px;
			box-shadow: 0px 2px 4px rgba(62, 100, 200, 0.1), 0px 3px 8px rgba(62, 100, 200, 0.15);
		}
	}
}
