@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';

.product-item__common {
	.textarea-field.full {
		label {
			top: 5px;
		}
	}
	.product-item__common-image {
		max-width: 420px;
	}

	.common-description__checkbox {
		display: inline-flex;
		padding: 5px;
		border-radius: 8px;
		background: $color-app-background;
		min-height: 48px;

		.checkbox-item {
			border-radius: 8px;
			padding: 5px 25px;
			background-color: $color-border-light;
			font-weight: 500;
			font-size: 16px;
			cursor: pointer;
			transition: $transition-main;
			outline: none;
			border: none;

			&:hover {
				background-color: $color-app-background;
				box-shadow: $card-shadow;
			}

			&.active {
				background-color: $color-blue;
				color: $color-app-main;
				cursor: default;

				&:hover {
					box-shadow: none;
				}
			}

			&:first-child {
				margin-right: 4px;
			}
		}
	}

	.product-item__common-description {
		margin-bottom: 16px;

		.product-item__common-box {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			margin-bottom: 16px;
			width: 100%;
			& + .input-main-container {
				margin-bottom: 16px;
			}
			&:not(:first-child) {
				.input-main-container {
					max-width: 275px;
				}
			}
		}

		.product-item__common-item {
			display: flex;
			justify-content: space-between;

			& > .input-main-container {
				max-width: 200px;
			}
		}

		input {
			background: $color-app-background;
		}

		.common-description__balance {
			max-width: 165px;
		}
	}

	.common-description__description {
		height: 195px;
		margin-bottom: 30px;
	}

	.textarea-field > textarea {
		background-color: $color-app-background;
		min-height: 195px;
		margin-bottom: 30px;
		margin-top: 0;
	}
}
