@import '../../../assets/styles/colors';

.group__page {
	height: 100%;
	.group__header {
		display: flex;
		justify-content: space-between;
	}

	.page-wrapper:last-child {
		background-color: $color-app-main;
		height: 100%;

		.left-column {
			.column {
				max-width: 590px;
				width: 100%;

				.input-main-container {
					max-width: 590px;

					input {
						background-color: #FAFAFA;
					}
				}

				.textarea-field > textarea {
						background-color: #FAFAFA;
						min-height: 220px;
				}
			}
		}
	}
}