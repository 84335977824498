@import '../../assets/styles/colors';

.textarea-field {
	position: relative;
	width: 100%;
	transition: margin-bottom 0s;

	& > label {
		position: absolute;
		top: 24px;
		left: 17px;
		color: #959595;
		transition: all 0.15s ease-in-out;
	}

	& > textarea {
		width: 100%;
		margin-top: 8px;
		padding: 13px 16px;
		font-size: 16px;
		background-color: #ffffff;
		border: 1px solid #d9d8dc;
		transition: all 0.15s ease-in-out;
		outline: none;
		resize: none;

		&::-webkit-scrollbar {
			width: 0px;
		}

		&:focus {
			padding: 17px 16px 9px;
			border-color: $color-blue-focus;
			outline: unset;
		}

		&:not([value='']) {
			padding: 17px 16px 9px;
		}
	}

	&.full > label,
	& > textarea:focus + label {
		top: 11px;
		font-size: 12px;
	}

	&.has-error {
		&.full {
			& > label {
				color: #e9534a;
				top: 14px;
				font-size: 14px;
			}
		}
	}
}
