@import "../../../../assets/styles/colors";

.product-item__page-body {
  flex-grow: 1;

  .page-wrapper {
    height: 100%;
    background-color: $color-app-main;
  }
}

.input-text {
  background: $color-app-background;
  border: 1px solid $color-border;
  font-size: 16px;
}
