@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.app-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 26px;
	min-height: 60px;
	background-color: $color-app-main;
	border-bottom: 1px solid $color-border-light;

	&__logo {
		a {
			display: flex;
			align-items: center;

			.x-icon {
				margin-right: 6px;
				height: 20px;
				width: 24px;
				@include bg-image('svg/x-cluster.svg');
			}

			.x-name {
				height: 18px;
				width: 95px;
				@include bg-image('svg/admin.svg');
			}
		}
	}

	.mobile-menu-wrapper {
		width: 24px;
		height: 24px;
		cursor: pointer;
		@include bg-image('svg/drawer.svg');
	}

	.burger-menu {
		position: absolute;
		top: 60px;
		left: -100%;
		display: flex;
		justify-content: center;
		width: 265px;
		height: calc(100vh - 60px);
		z-index: 100;
		background-color: $color-app-main;
		opacity: 0;
		transition: all 0.3s ease-in-out;

		&.show {
			left: 0;
			opacity: 1;
		}

		& > nav {
			flex-wrap: wrap;
			align-content: flex-start;
			height: auto;
			margin: 0;
			padding: 15px 0;
			overflow-y: auto;

			& > .nav-link {
				width: 100%;
				height: auto;

				& + .nav-link {
					margin-top: 10px;
				}
			}
		}
	}

	nav {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-left: 20px;
		overflow-x: auto;
		overflow-y: hidden;
		height: 100%;
		width: 100%;

		.nav-link {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			width: 140px;
			height: 100%;
			padding: 5px 0;
			font-weight: 500;
			font-size: 14px;
			letter-spacing: 0.04em;
			opacity: 0.5;
			transition: $transition-main;
			border-right: 1px solid transparent;

			&--active {
				background-color: $color-blue-ghost;
				border-right: 1px solid $color-blue-focus;
			}

			&--active,
			&:hover {
				background-color: $color-blue-ghost;
				border-right: 1px solid $color-blue-focus;
				opacity: 1;
			}
		}
	}

	&.hide {
		justify-content: center;

		& > .mobile-menu-wrapper {
			display: none;
		}
	}

	.info-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;
	}

	@include max-width(768px) {
		padding: 0 13px;

		.burger-menu {
			top: 60px;
			width: 100%;

			&.show {
				height: calc(100vh - 60px);
			}
		}

		nav {
			.nav-link {
				border-right: none;
				&::after {
					content: '';
					display: block;
					position: absolute;
					left: 0;
					bottom: -1px;
					height: 1px;
					width: 100%;
					background-color: transparent;
					transition: $transition-main;
				}

				&--active {
					background-color: $color-blue-ghost;
					border-right: none;

					&::after {
						background-color: $color-blue-focus;
					}
				}

				&--active,
				&:hover {
					opacity: 1;
				}
			}
		}
	}
}
