.empty-element-block {
	position: relative;
	&__container {
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 440px;
		height: 100px;
		margin-bottom: 16px;
		border: 1px solid rgba(55, 59, 79, 0.24);
		border-radius: 8px;
		cursor: pointer;

		& > span {
			width: 100%;
			text-align: center;
		}
	}

	& > .delete-button {
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
		z-index: 10;
	}
}
