@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/mixins';

.user-item__user-info {
	display: flex;
	justify-content: space-between;
	padding: 16px 0;

	@include max-width(768px) {
		flex-direction: column;
	}
}
