@import '../../../../../assets/styles/colors';

.location__map {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 387px;
	height: 180px;

	.modal-container {
		padding: 0;
		max-width: 900px;

		.btn-close {
			top: 0;
			right: -40px;
			filter: invert(1);
		}
	}

	.location__button {
		background-color: transparent;
		outline: none;
		border: none;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: $color-blue-focus;
		cursor: pointer;
		transition: 0.3s all;
	}
}
