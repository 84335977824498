	.popup__address {
		display: flex;
		flex-direction: column;
		min-width: 150px;
		margin-right: 30px;
		span:first-child {
			font-weight: normal;
			font-size: 12px;
			line-height: 20px;
		}

		span:last-child {
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
		}
	}