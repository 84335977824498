@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.regist-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: $color-app-background;

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 32px;
    max-width: 480px;
    background-color: $color-app-main;
    border: 1px solid $color-panel-border;
    border-radius: 8px;

    .regist-form {
      .phone-input-container {
        margin-bottom: 10px;
        font-family: inherit;

        .phone-input {
          padding: 13px 16px 13px 62px;
          height: auto;
          width: 100%;
          height: 48px;
          font-size: 16px;
          border-radius: 0;
          border: 1px solid $color-border;

          &:focus {
            border-color: $color-blue-focus;
          }
        }

        .phone-input-button {
          .selected-flag {
            width: 52px;
            padding-left: 14px;
          }
        }

        .phone-input-dropdown {
          box-shadow: none;
          border: 1px solid $color-border;
        }
      }

      .button-main {
        margin-top: 20px;
      }
    }

    .check-container {
      display: flex;
      margin-top: 12px;

      .check-text {
        margin-left: 8px;
        font-size: 12px;
      }
    }
  }
}
