@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/mixins';

.user-item {
	.requests-list {
		.table {
			height: 100%;
			justify-content: space-between;
		}
	}
}
