@import '../../../../assets/styles/colors';

.news-table {
	.table-titles-block {
		grid-template-columns: 52px 135px 318px 723px 95px;
		gap: 20px;
	}
	.table-rows-block {
		a:nth-child(odd) {
			background-color: $color-gray-light;
			display: inline-block;
			width: 100%;
			border-radius: 8px;
		}

		.table-row {
			grid-template-columns: 52px 135px 318px 723px 95px;
			gap: 20px;

			.row-item {
				display: flex;
				justify-content: flex-start;
			}
		}
	}
}
