@import '../../../../../../../assets/styles/colors';

.user-admin-notes {
	.notes-list__item {
		border-radius: 8px;
		padding: 8px;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		transition: all 0.15s ease-in-out;
		animation: fade 0.25s ease-out backwards;

		&:nth-child(odd) {
			background-color: $color-gray-light;

			.delete-button {
				&:hover {
					background-color: $color-input-error;
				}
			}
		}
		.note-info {
			display: flex;
			align-items: center;

			&__name {
				margin-left: 10px;
				color: $color-input-placeholder;
			}

			&__time {
				margin-left: 8px;
				color: $color-input-placeholder;
			}
		}

		.note-text {
			font-size: 16px;
			line-height: 24px;
		}
	}

	.delete-button {
		border-radius: 50%;

		&:hover {
			background-color: $color-input-error;
		}
	}
}
