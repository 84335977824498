.user-ban-modal {
	.user-ban-info {
		display: flex;

		.avatar {
			height: 60px;
			width: 60px;

			.avatar__image {
				height: 100%;
				width: 100%;
			}
		}
	}

	.user-ban-text {
		margin-left: 16px;

		& > p {
			font-size: 16px;
			line-height: 24px;

			& > span {
				font-weight: 600;
			}
		}
	}

	.buttons-block {
		display: flex;
		justify-content: center;
		margin-top: 40px;

		.button-main.lg {
			margin-left: 10px;
		}
	}
}
