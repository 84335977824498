@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table-pagination-block {
	display: flex;
	justify-content: center;

	& > .pagination-component {
		display: grid;
		max-width: 300px;
		grid-auto-flow: column;
		column-gap: 16px;
		padding: 8px;
		background-color: $color-app-main;

		&.standalone {
			border: 1px solid $color-border-light;
			border-radius: 8px;
		}

		.round-icon-button {
			background-color: transparent;
			border-radius: 50%;
			transition: $transition-main;

			&.disabled {
				opacity: 0.5;
				pointer-events: none;
				user-select: none;
			}

			&:hover {
				background-color: $color-blue-ghost;
			}

			&:last-child {
				transform: rotate(180deg);
			}
		}

		.text {
			display: grid;
			grid-auto-flow: column;
			column-gap: 8px;
			align-self: center;

			span {
				font-size: 16px;
			}
		}
	}
}
