@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.dropdown-options {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 47px;
	left: 0;
	width: 100%;
	background-color: $color-app-main;
	border: 1px solid $color-border;
	overflow-y: scroll;
	z-index: 1;
	transition: all 0.2s ease-out;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 0px;
	}

	.option {
		display: flex;
		align-items: center;
		padding: 12px 16px;
		background-color: $color-app-main;
		transition: $transition-main;
		cursor: pointer;

		&.active {
			opacity: 0.7;

			cursor: default;

			&:hover {
				background-color: transparent;
			}
		}

		&:hover {
			background-color: #373b4f24;
		}

		span {
			font-size: 16px;
		}
	}
}
