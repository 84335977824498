@import '../../assets/styles/colors';

.load__image {

  .hidden__input {
    display: none;
  }

  .load__image-load {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 180px;
    margin-bottom: 16px;
    border-radius: 4px;
    border: none;
    background-color: #E0E0E0;
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: #c5c5c5;
    }

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #44454D;
      padding: 20px 0 8px;
    }
  }

  .load__image-upload.image {
    height: 180px;
    display: flex;
    align-items: flex-end;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 16px;

    .load__image-load {
      height: 40px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);

      &:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }

      img {
        width: 24px;
        height: 24px;
        filter: invert(1);
      }

      span {
        padding: 0 0 0 10px;
        color: $color-app-main;
      }
    }
  }
}