@import '../../assets/styles/colors';

.app {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	overflow: hidden;
	height: 100vh;
	width: 100vw;
	background-color: $color-app-background;

	& > .container {
		flex: 1;
		display: flex;
		align-items: stretch;
		overflow: hidden;

		& > .page-container {
			flex: 1;
			overflow-y: auto;

			-ms-overflow-style: none;
			scrollbar-width: none;
		}
	}
}
