@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.edit__card {
	display: flex;

	button {
		&:not(:last-child) {
			margin-right: 8px;
		}

		&.edit__button {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: transparent;
			border: none;
			color: $color-red;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			margin-right: 64px;
			outline: none;
			cursor: pointer;

			& > i {
				display: block;
				width: 24px;
				min-width: 24px;
				height: 24px;
				margin-right: 8px;

				@include bg-image('svg/delete.svg', auto);
			}
		}
	}

	.button-main {
		&:last-child {
			box-shadow: 0px 2px 4px rgba(62, 100, 200, 0.1),
				0px 3px 8px rgba(62, 100, 200, 0.15);
		}
	}
}
