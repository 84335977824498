.products-table {
	.pagination-container {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 12px 0 16px;
	}

	.products-table {
		.table-titles-block {
			grid-template-columns: 345px 330px 170px 106px 79px 106px 106px 79px; //1438

			.right-text {
				display: flex;
				justify-content: flex-end;
			}
		}

		.table-row {
			grid-template-columns: 70px 275px 330px 170px 106px 79px 106px 106px 79px; //1438
			padding: 2px 0;

			.right-text {
				width: 100%;
				text-align: right;
				font-weight: normal;
				font-size: 16px;
				line-height: 24px;
			}
		}
	}
}
