@import '../../../../assets/styles/colors';

.checkbox-item {
	label {
		position: relative;
		padding-left: 25px;
		cursor: pointer;

		&:hover span {
			opacity: 0.7;
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 1px;
			width: 16px;
			height: 16px;
			border: 1px solid $color-border;
			border-radius: 2px;
			background: #fff;
			cursor: pointer;
		}
	}

	input {
		display: none;

		&:checked + label::before {
			border: none;
			background: $color-blue-focus;
		}

		&:checked + label::after {
			content: '';
			position: absolute;
			left: 1px;
			top: 4px;
			left: 9px;
			width: 1.7px;
			height: 10px;
			background-color: #fff;
			transform: rotate(40deg);
			pointer-events: none;
		}

		&:checked + label span::after {
			content: '';
			position: absolute;
			left: 1px;
			top: 8px;
			left: 4px;
			width: 1.7px;
			height: 4px;
			background-color: #fff;
			transform: rotate(-45deg);
			pointer-events: none;
		}
	}
}
