.product-item__page {
	display: flex;
	flex-direction: column;
	height: 100%;

	& > .page-wrapper:first-child {
		padding: 0;
		height: 65px;
	}

	.page-wrapper:last-child {
		background-color: #ffffff;
	}

	.icon-wrapper {
		width: 24px;
		height: 24px;
		margin-right: 8px;
	}

	.product-item__title {
		font-weight: 500;
		font-size: 20px;
		margin-bottom: 16px;

		h2 {
			font-size: 22px;
		}
	}
}
