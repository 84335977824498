@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.event-page {
	width: 100%;

	& > .page-wrapper:last-child {
		background-color: #fff;
	}

	& > .page-wrapper:first-child {
		padding: 0;
		height: 65px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		width: 100%;
		position: fixed;
		background-color: $color-app-background;
		padding: 10px 40px 10px 16px;
		z-index: 10;

		.buttons-panel-block {
			& > .edit__card {
				flex-direction: column;
				align-items: center;
				width: 100%;

				& > .edit__button {
					margin: 0;
				}

				& > button {
					width: 100%;
					max-width: 300px;

					& > .label {
						font-size: 14px;
					}

					& + button {
						margin-top: 10px;
					}
				}
			}

			.publish-button {
				width: 100%;
				max-width: 300px;

				& > .button-block {
					width: 100%;
					justify-content: center;
					margin: 10px 0 0;

					& > button {
						width: 100%;
						margin: 0;
					}

					.label {
						font-size: 14px;
					}
				}
			}
		}
	}

	&__body {
		display: flex;
		flex-wrap: wrap;
		width: 100%;

		& > .left-column {
			display: flex;
		}

		& > .column {
			margin-left: 30px;
		}
	}

	.button-block {
		display: flex;
		justify-content: flex-end;

		.button-main {
			margin-right: 5px;
		}
	}

	@include max-width(1440px) {
		.columns-container {
			.column {
				width: 100%;
				margin-top: 40px;
				margin-left: 0;
			}

			.left-column {
				flex-wrap: wrap;
				justify-content: space-between;

				& > .column {
					width: 100%;
					max-width: calc(50% - 15px);

					& + .column {
						margin-top: 40px;
						margin-left: 0;
					}
				}
			}
		}
	}

	@include max-width(992px) {
		&__header {
			padding: 10px 16px;
		}
		& > .page-wrapper:first-child {
			height: auto;
		}
	}

	@include max-width(768px) {
		.page-wrapper {
			padding: 16px 13px;

			&:first-child {
				height: auto;
			}

			& > .columns-container {
				flex-wrap: wrap;

				.left-column {
					flex-wrap: wrap;
					justify-content: space-between;

					& > .column {
						width: 100%;
						max-width: 100%;

						& + .column {
							margin-top: 40px;
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}
