@import '../../../../../../assets/styles/colors';

.tile-title {
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.04em;
	color: $color-app-main;
	margin-bottom: 10px;
	text-shadow: 5px 0px 10px rgba(10, 10, 10, 1);

	&.large {
		font-size: 28px;
		line-height: 32px;
	}
}
