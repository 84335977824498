@import '../../assets/styles/colors';

.main-block {
	display: flex;
	flex-wrap: wrap;
	width: 976px;
	margin: 60px auto;

	&:last-child {
		padding-bottom: 60px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding-bottom: 16px;
		border-bottom: 2px solid rgba(48, 50, 59, 0.19);
		margin-bottom: 20px;

		& > h3 {
			font-weight: 500;
			font-size: 28px;
			line-height: 32px;
			margin-bottom: 0;

			& > span {
				color: $color-gray;
				opacity: 0.5;
			}
		}
	}

	&__header-button {
		display: flex;
		align-items: center;
		cursor: pointer;

		& > .icon-container {
			margin-right: 15px;
		}
	}
}

.save-button-block {
	position: fixed;
	bottom: 0;
	display: flex;
	justify-content: flex-end;
	background-color: #fff;
	width: 100%;
	z-index: 10;

	& > .save-button {
		display: flex;
		justify-content: flex-end;
		width: 976px;
		margin: 0 auto;
		padding: 15px 0;
	}
}
