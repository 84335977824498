@import '../../../../../../assets/styles/colors';

.partner-info-block {
	& > span {
		display: block;
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 8px;
	}

	.partner-info {
		display: flex;
		align-items: center;
		padding: 7px;
		width: 290px;
		height: 45px;
		border-radius: 8px;
		background-color: $color-gray-light;

		.partner-name {
			margin-left: 20px;

			& > span {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
}
