@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/animations';
@import '../../../../assets/styles/mixins';

.form-card-component {
	display: grid;
	row-gap: 16px;
	padding: 18px;
	width: 100%;
	max-width: calc(100% / 3 - 8px);
	background-color: $color-app-main;
	border: 1px solid $color-border-light;
	border-radius: 8px;
	cursor: pointer;
	@include shift-out(-8px);
	transition: $transition-main;

	&.banned {
		border-color: $color-red;
		opacity: 0.5;
	}

	&:not(.banned):hover {
		border-color: $color-blue-focus;
	}

	.header {
		display: flex;
		justify-content: space-between;

		.profile {
			display: flex;
			align-items: center;

			.avatar-container {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 40px;
				width: 40px;
				margin-right: 8px;
				background-color: $color-border-light;
				border-radius: 50%;
				overflow: hidden;

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}

			.name {
				font-size: 18px;
				font-weight: 500;
			}
		}

		.form-status {
			height: 24px;
			width: 24px;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;

			&.red {
				background-color: rgba($color: $color-red, $alpha: 0.15);
			}

			&.green {
				background-color: rgba($color: $color-green, $alpha: 0.15);
			}

			&.blue {
				background-color: rgba($color: $color-blue-focus, $alpha: 0.15);
			}
		}
	}

	.body {
		display: flex;
		flex-wrap: wrap;
		row-gap: 8px;

		& > div {
			display: flex;
			gap: 8px;
			margin-right: 10px;
		}

		& > span {
			font-size: 14px;

			&:nth-child(2n - 1) {
				opacity: 0.7;
			}
		}
	}

	span.placeholder {
		font-size: 14px;
		opacity: 0.7;
	}

	.divider {
		height: 1px;
		background-color: $color-border-light;
	}

	.skills-container {
		display: flex;
		align-items: center;
		position: relative;
		overflow: hidden;

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: 32px;
			box-shadow: inset -20px 0px 10px -5px $color-app-main;
		}

		& > span {
			margin-right: 16px;
			font-size: 14px;
			opacity: 0.7;
		}

		.skills {
			display: grid;
			grid-auto-flow: column;
			column-gap: 8px;

			.skill-item {
				display: flex;
				align-items: center;
				height: 22px;
				padding: 0 8px;
				border-radius: 4px;

				&.senior {
					background-color: $color-skill-senior;
				}

				&.middle {
					background-color: $color-skill-middle;
				}

				&.junior {
					background-color: $color-skill-junior;
				}

				span {
					font-size: 12px;
					font-weight: 500;
					color: $color-app-main;
					text-transform: uppercase;
					white-space: nowrap;
				}
			}
		}
	}

	@include max-width(1200px) {
		max-width: calc(100% / 2 - 8px);
	}

	@include max-width(768px) {
		max-width: 100%;
	}
}
