@import '../../../assets/styles/colors';

.panel {
	padding: 20px;
	background: $color-app-main;
	border: 1px solid $color-panel-border;
	border-radius: 8px;

	&.disabled {
		opacity: 0.4;
	}
}
