@import '../../../../assets/styles/mixins';

.user-item {
	width: 100%;
	background-color: #fff;

	.form-status {
		display: none;
	}

	&.banned {
		.columns-container {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	.requests-list {
		margin-top: 85px;
		height: 100%;
	}

	.columns-container {
		padding-bottom: 16px;
		padding: 0 40px 16px 40px;
		height: 100%;

		.column {
			height: 100%;
		}

		.panel {
			min-height: 300px;
			padding: 20px 5px 20px 20px;
		}
	}

	.user-item-questionnaire {
		position: relative;
		padding-right: 20px;
	}

	@include max-width(1200px) {
		.columns-container {
			flex-direction: column;

			.column {
				width: 100%;

				&:nth-child(2) {
					margin-left: 0;
				}
			}

			.panel {
				width: 100%;
			}
		}
	}

	@include max-width(480px) {
		.columns-container {
			padding-right: 15px;
			padding-left: 15px;
		}
	}
}
