@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.transactions-table {
	p {
		font-size: 16px;
		line-height: 24px;
	}

	.table-titles-block {
		grid-template-columns: 135px 48px 240px 145px 322px 48px 240px 96px;
		gap: 20px;
	}

	.table-row {
		grid-template-columns: 135px 48px 240px 145px 322px 48px 240px 96px;
		gap: 20px;
		padding: 2px 0;

		&:nth-child(odd) {
			background-color: $color-gray-light;
			width: 100%;
			border-radius: 8px;
		}

		.description-popup {
			display: none;
			opacity: 0;
			width: 400px;
			padding: 4px 8px;
			top: calc(100% - 4px);
			left: 15px;
			border-radius: 4px;
			background: $color-landing-background;
			border: 1px solid $color-border;
			box-shadow: 0px -8px 10px rgba(67, 72, 114, 0.0456112), 0px -2px 4px rgba(67, 72, 114, 0.07),
				0px 30px 24px rgba(67, 72, 114, 0.035), 0px 12px 10px rgba(67, 72, 114, 0.0456112),
				0px 4px 4px rgba(67, 72, 114, 0.07);
			opacity: 0;
			z-index: 100;
			transition: all 0.2s ease-out;

			&::after {
				content: '';
				position: absolute;
				z-index: 1;
				top: -6px;
				left: 30px;
				width: 10px;
				height: 10px;
				background: $color-landing-background;
				border-left: 1px solid $color-border;
				border-top: 1px solid $color-border;
				transform: rotate(45deg);
			}
		}

		.transactions-description {
			position: relative;
			cursor: pointer;

			&:hover .description-popup {
				position: absolute;
				display: block;
				opacity: 1;
				top: calc(100% + 4px);
			}
		}
	}
}
