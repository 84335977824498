@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.forms-table {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	position: relative;
	width: 100%;

	&-body {
		display: flex;
		flex-wrap: wrap;
		gap: 12px;
		margin: 24px 0;
	}

	.preloader-container {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(255, 255, 255, 0.6);
		z-index: 1;
	}

	.pagination-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
