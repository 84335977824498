@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/variables';
.product-item__page{
	.product-item__chars{
		display: grid;
		grid-template-columns: 1.7fr 3.3fr;
		gap: 12px 16px;
		
		.product-item__title{
			grid-column: 1/3;
			margin-bottom: 0;
		}
	
		.chars__subtitle{
			font-weight: 600;
			font-size: 12px;
			text-transform: uppercase;
			opacity: 0.5;
		}
	
		.product-item__chars-row{
			display: grid;
			grid-template-columns: 1.7fr 3.3fr;
			gap: 12px 16px;
			grid-column: 1/3;
		}
		
		
		.input-text{
			padding: 11px 16px;
		}
	
		.product-item__add-char{
			text-align: start;
			outline: none;
			cursor: pointer;
			transition: $transition-main;
	
			&:hover{
				background-color: $color-app-main;
				box-shadow: $card-shadow;
	
			}
	
			&.input-text{
				span{
					opacity: 0.6;
				}
			}
		}
	}
}