@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.checkbox-main {
	display: flex;
	align-items: center;
	gap: 10px;
	border-radius: 4px;
	cursor: pointer;

	input {
		position: absolute;
		height: 0;
		width: 0;
		visibility: hidden;

		&:checked + span {
			background-color: $color-blue;
			border-color: $color-blue;

			&:hover {
				background-color: $color-blue-focus;
				border-color: $color-blue-focus;
			}
		}
	}

	span {
		display: block;
		height: 16px;
		width: 16px;
		background-color: $color-app-main;
		border: 1px solid $color-border;
		border-radius: 4px;
		transition: $transition-main;

		&:hover {
			border-color: $color-blue-focus;
		}
	}

	p {
		font-weight: normal;
		font-size: 16px;
		line-height: 140%;
		position: relative;
	}
}
