@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';

.table-title-block {
	display: flex;
	align-items: center;
	height: 32px;
	padding: 0 8px;
	font-family: 'Commissioner';
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 100%;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: $color-gray;

	span {
		font-family: 'Commissioner';
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 100%;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: $color-gray;
	}

	&.sort {
		display: flex;
		align-items: center;
		cursor: pointer;

		& > i {
			display: block;
			width: 16px;
			height: 16px;

			@include bg-image('svg/dropdown.svg', auto);
		}
	}
}
