@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/mixins';

.user-item-header {
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 100%;
	background-color: $color-app-background;
	padding: 10px 40px 10px 16px;

	.is-user-banned {
		margin-right: 66px;
	}

	.header-buttons {
		display: flex;
		align-items: center;
	}

	.buttons-panel-block {
		.is-user-banned {
			margin-right: 36px;
		}

		& > .edit__card {
			flex-direction: column;
			align-items: center;
			width: 100%;
			margin-top: 10px;
		}
	}

	.is-user-banned {
		.is-banned-input {
			display: none;

			&:checked + .is-banned-label::after {
				background-color: $color-red-focus;
			}

			&:checked + .is-banned-label::before {
				border: 1.5px solid $color-red-focus;
				right: -37px;
			}

			&:checked + .is-banned-label {
				color: $color-red-focus;
			}
		}

		.is-banned-label {
			position: relative;
			transition: $transition-main;
			font-family: Commissioner;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			cursor: pointer;
			color: $color-input-placeholder;

			&::after {
				content: '';
				position: absolute;
				right: -36px;
				top: 5px;
				width: 26px;
				height: 12px;
				border-radius: 20px;
				background-color: $color-border;
				transition: $transition-main;
			}

			&::before {
				content: '';
				position: absolute;
				right: -26px;
				top: 2px;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				border: 1.5px solid $color-border;
				background-color: #fff;
				z-index: 1;
				transition: $transition-main;
			}
		}
	}

	@include max-width(480px) {
		align-items: flex-start;
		padding-right: 10px;
	}
}
