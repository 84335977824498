@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.input-main-container {
	position: relative;
	width: 100%;

	&.error {
		& > label {
			color: #e9534a;
		}
	}

	input {
		height: 48px;
		width: 100%;
		padding: 13px 16px;
		background-color: $color-app-main;
		border: 1px solid $color-border;
		outline: none;
		font-size: 16px;
		transition: $transition-main;

		&::placeholder {
			color: $color-input-placeholder;
		}

		&:disabled {
			color: #959595;
		}

		&:focus,
		&:not([value='']) {
			padding-top: 20px;
			padding-bottom: 7px;

			& + label {
				top: 5px;
				font-size: 12px;
			}
		}

		&:focus {
			border-color: $color-blue-focus;
		}

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	label {
		position: absolute;
		top: 14px;
		left: 17px;
		color: $color-input-placeholder;
		transition: $transition-main;
	}
}
