@import '../../../assets/styles/colors';

.comments-block {
	&__tabs-button {
		display: flex;
		margin-bottom: 20px;

		& > .comment-tab-block {
			background: #bdbfc5;
			border-radius: 6px;
			font-weight: 600;
			font-size: 12px;
			line-height: 140%;
			padding: 8px 10px;
			color: $color-app-main;
			cursor: pointer;

			& + .comment-tab-block {
				margin-left: 10px;
			}

			&.active {
				background: #5d83e4;
			}
		}
	}
}
