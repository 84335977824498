@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
// @import '../../assets/styles/mixins';

.button-main {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: auto;
	border: none;
	border-radius: 12px;
	padding: 16px;
	outline: none;
	cursor: pointer;
	transition: $transition-main;

	&:not(:disabled):hover {
		.bg {
			background-color: $color-blue-focus;
		}

		&::after {
			box-shadow: $button-blue-shadow;
		}
	}

	&:not(:disabled).white {
		.bg {
			background-color: $color-app-main;
		}

		&:hover {
			.bg {
				background-color: $color-gray-light;
			}

			&::after {
				box-shadow: $button-white-shadow;
			}
		}

		.label {
			color: $color-text-black;
		}
	}

	i {
		display: inline-block;
		width: 100%;
		height: 100%;
	}

	&:not(:disabled).red {
		.bg {
			background-color: $color-red;
		}

		&:hover {
			.bg {
				background-color: $color-red-focus;
			}

			&::after {
				box-shadow: $button-red-shadow;
			}
		}
	}

	&:not(:disabled).gray {
		.bg {
			background-color: $color-button-disabled;
		}

		&:hover {
			.bg {
				background-color: $color-input-placeholder;
			}

			span {
				color: $color-gray-light;
			}

			&::after {
				box-shadow: $button-gray-shadow;
			}
		}
	}

	&:not(:disabled).green {
		.bg {
			background-color: $color-green;
		}

		&:hover {
			.bg {
				background-color: $color-green-focus;
			}

			&::after {
				box-shadow: $button-green-shadow;
			}
		}
	}

	&.loading,
	&:disabled {
		.bg {
			background-color: $color-button-disabled;
		}

		pointer-events: none;
		user-select: none;
	}

	&.loading {
		cursor: progress;
		user-select: none;
	}

	&::after {
		content: '';
		position: absolute;
		top: 8px;
		left: 8px;
		height: calc(100% - 8px);
		width: calc(100% - 16px);
		box-shadow: 0 0 0 transparent;
		transition: $transition-main;
		z-index: -1;
	}

	&.md1 {
		padding: 9px 30px;

		.label {
			font-size: 16px;
		}
	}

	&.md2 {
		padding: 9px 50px;

		.label {
			font-size: 16px;
		}
	}

	&.lg {
		padding: 16px 72px;

		.label {
			font-size: 16px;
		}
	}

	&.s {
		padding: 8px 20px;
	}

	&.ss {
		padding: 5px 12px;

		& > label {
			width: 100px;
		}
	}

	.bg {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: $color-blue;
		border-radius: 12px;
		transition: $transition-main;
	}

	.label {
		display: flex;
		align-items: center;
		position: relative;
		font-weight: 600;
		letter-spacing: 0.04em;
		color: $color-app-main;

		i {
			background-repeat: no-repeat;
			background-position: left center;
			margin-right: 10px;
		}
	}
}
