@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';

.offices-panel-address {
	display: flex;
	align-items: center;

	&__image {
		flex-shrink: 0;
		width: 120px;
		height: 120px;
		background-position: center;
		background-size: cover;
		border-radius: 4px;
	}

	&__info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 24px;
		padding: 16px 0;
		height: 100%;
	}

	&__title {
		margin-bottom: 5px;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0.04em;
	}

	&__address {
		font-size: 14px;
		line-height: 20px;
		opacity: 0.7;
	}

	.office_status-item {
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		display: flex;
		align-items: center;

		i {
			display: block;
			margin-right: 4px;
			width: 24px;
			height: 10px;
		}

		&-approved {
			color: $color-green;

			i {
				@include bg-image('svg/approved.svg', center);
			}
		}

		&-rejected {
			color: $color-red;

			i {
				@include bg-image('svg/status-icons/close-red.svg', center);
			}
		}

		&-moderating {
			color: $color-blue-focus;

			i {
				@include bg-image('svg/status-icons/eye-blue.svg', center);
			}
		}
	}
}
