.select-category-block {
	& > .dropdown-main-container {
		margin-bottom: 8px;

		&.disabled {
			opacity: 0.8;
			cursor: default;

			& > .label {
				border: 1px solid #d9d8dc;
				cursor: default;
			}
		}
	}

	& > .input-main-container {
		margin-bottom: 8px;
	}

	.hide-title {
		display: flex;
		align-items: center;

		& > label {
			margin-right: 5px;
		}
	}

	&__buttons {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
	}
}
