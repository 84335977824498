@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.global-not {
	padding: 12px 16px;
	background: $color-app-main;
	text-align: center;
	box-shadow: 0px -8px 10px rgba(67, 72, 114, 0.0456112),
		0px -2px 4px rgba(67, 72, 114, 0.07), 0px 30px 24px rgba(67, 72, 114, 0.035),
		0px 12px 10px rgba(67, 72, 114, 0.0456112),
		0px 4px 4px rgba(67, 72, 114, 0.07);
	border-radius: 8px;
	opacity: 0;
	transition: $transition-main;

	&.error {
		background: $color-input-error;
		color: #fff;
	}
	&.success {
		background: $color-green;
		color: #fff;
	}
	&.info {
		background: $color-blue-focus;
		color: #fff;
	}

	&.visible {
		opacity: 1;
	}
}
