@import '../../../../assets/styles/colors';

.form-header {
	margin-bottom: 32px;

	h1 {
		font-weight: 500;
		font-size: 28px;
		line-height: 32px;
		letter-spacing: 0.04em;
	}

	p {
		margin-top: 16px;
		font-size: 16px;
		line-height: 24px;
		color: $color-text-black;
		opacity: 0.7;
	}
}
