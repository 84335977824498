@import '../../../assets/styles/colors';

.comment-block {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 30px;

	&__avatar {
		min-width: 40px;
		width: 40px;
		height: 40px;
		margin-right: 16px;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50%;
		}
	}

	&__body {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		max-width: calc(100% - 58px);
	}

	&__header {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 18px;

		&__name {
			p {
				font-weight: 600;
				font-size: 16px;
				line-height: 20px;
			}
		}

		&__time {
			p {
				font-size: 14px;
				line-height: 20px;
				color: $color-input-placeholder;
			}
		}
	}

	&__text {
		p {
			font-size: 16px;
			line-height: 24px;
		}
	}

	&__buttons {
		display: flex;
		gap: 15px;
		width: 100%;
		margin-top: 20px;
		margin-left: 56px;

		.label > i {
			margin-right: 0;
		}
	}
}
