@import '../../../assets/styles/colors';

.quotas-table{

	.table-titles-block{
		grid-template-columns: 3fr 6fr 2fr 2fr 2fr 1fr 6fr;
		gap: 20px;

		.right-text{
			display: flex;
			justify-content: flex-end;
		}
	}

	.table-row{
		grid-template-columns: 3fr 6fr 2fr 2fr 2fr 1fr 6fr;
		gap: 20px;
		padding: 2px 4px;
		
		.right-text{
			width: 100%;
			text-align: right;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
		}
	
		&:last-child p{
			width: 100%;
			text-align: left;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			background-color: transparent;
			border-radius: 0;
			color: $color-text-black;
		}

	}

}