/* The switch - the box around the slider */
@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.switch-block {
	--color: #959595;
	--color-active: #fd4747;

	display: flex;
	align-items: center;
	margin-right: 36px;

	.switch-input {
		display: none;

		&:checked + .switch-text::after {
			background-color: var(--color-active);
		}

		&:checked + .switch-text::before {
			border: 1.5px solid var(--color-active);
			right: -37px;
		}

		&:checked + .switch-text {
			color: var(--color-active);
		}
	}

	.switch-text {
		position: relative;
		transition: $transition-main;
		font-family: Commissioner;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		cursor: pointer;
		color: var(--color);

		&::after {
			content: '';
			position: absolute;
			right: -36px;
			top: 5px;
			width: 26px;
			height: 12px;
			border-radius: 20px;
			background-color: var(--color);
			transition: $transition-main;
		}

		&::before {
			content: '';
			position: absolute;
			right: -26px;
			top: 2px;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			border: 1.5px solid $color-border;
			background-color: #fff;
			z-index: 1;
			transition: $transition-main;
		}
	}

	&.custom {
		.switch-input {
			display: none;

			&:checked + .switch-text::after {
				background-color: attr(data-point-color url);
			}

			&:checked + .switch-text::before {
				border: 1.5px solid attr(data-point-color url);
			}

			&:checked + .switch-text {
				color: attr(data-label-color);
			}
		}

		.switch-text {
			&::after {
				content: '';
				position: absolute;
				right: -36px;
				top: 5px;
				width: 26px;
				height: 12px;
				border-radius: 20px;
				background-color: $color-border;
				transition: $transition-main;
			}

			&::before {
				content: '';
				position: absolute;
				right: -26px;
				top: 2px;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				border: 1.5px solid $color-border;
				background-color: #fff;
				z-index: 1;
				transition: $transition-main;
			}
		}
	}
}
