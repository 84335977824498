.group__modal {
	&-header {
		font-weight: 500;
		font-size: 28px;
		line-height: 32px;
		margin-bottom: 20px;
		padding: 24px 20px 0 40px;
	}

	.points-modal__search-wrapper {
		padding: 0 20px 0 40px;
		.search-dropdown {
			max-width: 750px;
		}
	}

	.table {
		padding: 0;
		border: none;
		margin-top: 20px;
		padding: 0 20px 30px 40px;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-bottom: 1px solid rgba(48, 50, 59, 0.15);
		height: 370px;
		overflow-y: scroll;
		scrollbar-width: none;

		.table-inner-block {
			overflow-y: auto;
			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}

		.table-rows-block {
			.group__modal-box {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 8px;

				.table-request-row {
					padding: 8px 16px;
					grid-template-columns: 32px 5.5fr 2fr 1fr;

					&__image {
						width: 32px;
						height: 32px;
						border-radius: 50%;
						background-size: contain;
						background-position: center;
					}

					.table-request-row__name {
						margin-left: 12px;
						font-weight: normal;
						font-size: 16px;
						line-height: 100%;
					}

					.table-request-row__phone {
						font-weight: normal;
						font-size: 16px;
						line-height: 100%;
					}

					&__reject-button,
					&__accept-button {
						display: none;
					}
				}

				.delete__button {
					max-width: 55px;
					width: 100%;
				}
			}
		}

		.modal-wrapper {
			display: none;
		}

		.table-pagination-block {
			display: none;
		}
	}

	&-buttons {
		padding: 12px 20px 12px 40px;
		display: flex;
		justify-content: flex-end;
	}
}
