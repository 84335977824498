@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.info-page-add-blocks {
	width: 50%;

	.panel {
		background-color: $color-app-background;

		.panel-title {
			width: 100%;
			margin-bottom: 16px;
		}
	}

	.info-page-add-blocks_buttons {
		display: flex;
		justify-content: center;

		.button-main {
			margin-left: 6px;
			width: 211px;

			.label > i {
				height: 21px;
				opacity: 0.9;
			}
		}
	}
}
