.filter {
	&.hide {
		display: none;
	}

	&__title {
		margin-bottom: 10px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0.04em;
	}

	&__items {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 4px;

		&.checkbox {
			flex-direction: column;
			flex-wrap: nowrap;
			align-items: start;
		}
	}

	&--whith-subtitles {
		display: flex;
		flex-wrap: wrap;

		.filter__title {
			width: 100%;
			font-weight: normal;
			font-size: 12px;
			line-height: 24px;
			opacity: 0.5;
		}
	}
}
