@import '../../../../assets/styles/colors';

.panel-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 4px;
	font-weight: 500;
	font-size: 20px;
	line-height: 32px;
	letter-spacing: 0.04em;
	color: $color-text-black;

	&__text,
	&__children {
		font-weight: inherit;
		letter-spacing: inherit;
	}
}
