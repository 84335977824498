@import '../../../../../../assets/styles/mixins';

.user-info-block {
	display: flex;
	align-items: center;

	.user-name {
		margin-left: 22px;

		& > span {
			font-weight: 500;
			font-size: 28px;
			line-height: 32px;
		}
	}

	@include max-width(768px) {
		margin-bottom: 20px;
	}
}
