@import '../../assets/styles/colors';

.comments-page {
	display: flex;
	width: 100%;
	height: 100%;
	& > .page-wrapper {
		overflow: hidden;
		background-color: $color-app-main;

		& > .columns-container {
			overflow: hidden;
			gap: 40px;

			& > .comments-table {
				max-width: 100%;
				width: 100%;
			}

			& > .panel {
				padding: 0;
				width: 100%;
			}
		}
	}

	.panel {
		transition: all 0.3s ease-in-out;
		transform: translateX(0);
		&.hide {
			transform: translateX(100%);
		}
	}

	.modal-wrapper {
		& > .modal-container {
			max-width: 600px;
		}
	}
}
