@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.quotas {
	padding: 16px 20px;

	.modal-wrapper.profile .modal-container {
		max-width: 510px;
		padding: 0;
	}

	.quotas__header {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;

		.label {
			color: $color-text-black;
			transition: all 0.15s ease-in-out;

			&::before,
			&::after {
				content: '';
				position: absolute;
				background-color: $color-text-black;
				transition: all 0.15s ease-in-out;
				top: 50%;
			}

			&::before {
				width: 16px;
				height: 2px;
				left: -23px;
			}

			&::after {
				height: 16px;
				width: 2px;
				left: -16px;
				margin-top: -7px;
			}
		}

		.button-main {
			padding: 9px 30px 9px 50px;

			&:hover {
				.label {
					color: $color-group-bg;

					&::before,
					&::after {
						background-color: $color-group-bg;
					}
				}
			}
		}
	}

	@include max-width(768px) {
		padding: 0;

		.quotas__header {
			.button-main {
				margin-top: 10px;
				padding: 9px 15px 9px 40px;

				.label {
					font-size: 14px;
				}
			}
		}
	}
}

.delete__quotas {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	color: $color-red;
	margin-top: 16px;
	position: relative;
	outline: none;

	i {
		position: absolute;
		top: 50%;
		left: -25px;
		transform: translateY(-50%);
		background-image: url('../../../assets/media/svg/denied.svg');
		background-repeat: no-repeat;
		background-size: contain;
		width: 25px;
		height: 25px;
	}
}
