@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";
@import "../../assets/styles/variables";

.dropzone-container {
  height: 735px;

  .pagination-component {
    background-color: transparent;
    justify-content: center;
  }
}

.preimage__wrapper {
  position: relative;
  display: inline-flex;
  width: 160px;
  height: 160px;
  pointer-events: auto;
}

.dropzone__preview {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .preimage-gallery {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
    top: 0;
    left: 0;
    padding-bottom: 40px;
    pointer-events: none;
  }

  .dropzone {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    outline: none;
    cursor: pointer;

    .dropzone-input-info {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon-wrapper {
        width: 132px;
        height: 132px;
        margin-bottom: 26px;

        i {
          display: block;
          width: 100%;
          height: 100%;
          @include bg-image("svg/DragNDrop.svg", center);
        }
      }

      p {
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        letter-spacing: 0.04em;
      }

      .dropzone__footnote {
        opacity: 0.6;
        margin-bottom: 40px;
      }

      span {
        font-size: 16px;
        line-height: 24px;
        opacity: 0.6;
        margin-bottom: 40px;
      }
    }

    .fake-button {
      display: flex;
      padding: 8px 30px;
      cursor: pointer;
      border-radius: 8px;
      background-color: $color-blue-ghost;

      .icon-wrapper {
        margin: 0 7px 0 0;
        width: 24px;
        height: 24px;

        i {
          display: block;
          width: 100%;
          height: 100%;
          @include bg-image("svg/downloads.svg", center);
        }
      }

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        color: $color-blue-focus;
        opacity: 1;
      }

      &--pagination {
        position: absolute;
        background-color: transparent;
        bottom: 0;
        left: 0;
        padding: 8px 30px 8px 0;
      }
    }
  }
}

.preimage {
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.preimage__container {
  display: flex;
  min-width: 100%;
}

.btn-wrapper {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  width: 28px;
  height: 28px;
}
.preimage__delete-btn {
  width: 24px;
  height: 24px;
  position: relative;
  border: none;
  outline: none;
  background-color: rgba(48, 50, 59, 0.89);
  opacity: 0.7;
  border-radius: 4px;
  cursor: pointer;
  @include bg-image("svg/delete-white.svg", center);
  transition: ease-in-out 0.15s;

  &:hover {
    opacity: 1;
  }
}
