@import '../../../../../../../assets/styles/colors';
@import '../../../../../../../assets/styles/variables';

.add-button-wrapper {
	position: relative;

	button {
		border: none;
		outline: none;
		background-color: transparent;
		cursor: pointer;
	}

	.add-button {
		width: 100%;
		z-index: 2;
		background-color: $color-gray-light;
	}

	.add-groups-list {
		position: absolute;
		display: none;
		padding: 0 0 8px 0;
		left: 0;
		top: 0;
		width: 100%;
		height: 0;
		overflow-y: scroll;
		opacity: 0;
		list-style: none;
		background-color: #fff;
		border: 1px solid rgba(48, 50, 59, 0.15);
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
		border-radius: 8px;
		transition: $transition-main;

		&.show {
			height: auto;
			max-height: 200px;
			display: block;
			opacity: 1;
			transition: $transition-main;
		}

		&.without-scroll {
			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				width: 0;
			}
		}

		&-item {
			.choose-item {
				padding: 4px 20px;
				width: 100%;
				text-align: start;
			}
		}

		& > li {
			&:hover {
				background-color: $color-gray-light;
			}
		}

		.first-list-option {
			background-color: $color-gray-light;
			width: 100%;
			margin-bottom: 4px;
			height: 48px;
			border-radius: 8px 8px 0px 0px;

			.close-list {
				display: flex;
				align-items: center;
				padding-left: 20px;
				width: 100%;
				height: 100%;

				.add-text {
					margin-left: 17px;
					font-size: 16px;
					line-height: 24px;
				}
			}
		}
	}
}
