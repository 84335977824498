@import '../../../assets//styles/colors';

.table-rows-block {
	display: grid;
	width: 100%;
	margin-bottom: 10px;

	a:nth-child(odd) {
		background-color: $color-gray-light;
		display: inline-block;
		width: 100%;
		border-radius: 8px;
	}
}
