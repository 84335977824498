@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.knowledge-base-item {
	height: 100%;
	display: flex;
	flex-direction: column;

	.knowledge-base-item__header {
		display: flex;
		justify-content: space-between;
		padding: 6px 40px;
		width: 100%;
		position: fixed;
		background-color: $color-app-background;
		padding: 10px 40px 10px 16px;
		z-index: 10;
	}

	.page-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;
		background-color: $color-app-main;

		.panel {
			width: 50%;
			margin-top: 65px;
			margin-bottom: 40px;
		}

		.info-page-add-gallery,
		.info-page-add-blocks,
		.info-panel-tags {
			.panel {
				width: 100%;
			}
		}
	}

	.knowledge-base-item__types-block {
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;

		.title {
			display: flex;
			align-items: center;
			& > span {
				font-weight: 500;
				font-size: 20px;
				line-height: 24px;
			}
		}

		.knowledge-base-item__types-btns {
			display: flex;

			button {
				margin-left: 4px;

				&:disabled {
					& > .bg {
						background-color: $color-blue-focus;
					}
				}
			}
		}
	}

	.react-datepicker-wrapper {
		width: 100%;
	}

	.react-datepicker__tab-loop {
		.react-datepicker-popper {
			.react-datepicker {
				left: 50%;

				&__triangle {
					left: 50% !important;
				}
			}
		}
	}

	.load__image {
		height: 488px;
		margin-bottom: 24px;

		.load__image-load {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.load__image-upload {
			height: 100%;
		}
	}

	.input-main-container {
		max-width: 100%;
		margin-bottom: 8px;

		& > input {
			background-color: #fafafa;
			border: 1px solid rgba(55, 59, 79, 0.24);
		}
	}

	.button-block {
		display: flex;
		justify-content: flex-end;
		.button-main {
			margin-right: 5px;
		}
	}
}
