@import '../../assets/styles/mixins';
@import '../../assets/styles/animations';

.preloader-component {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	width: 100%;
	height: 100%;

	.preloader {
		height: 28px;
		width: 28px;
		@include bg-image('img/preloader.png', auto);
		@include spin;
	}
}
