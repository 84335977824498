$transition-main: all 0.15s ease-in-out;

$card-shadow: 0px -8px 10px rgba(67, 72, 114, 0.0456112), 0px -2px 4px rgba(67, 72, 114, 0.07),
	0px 30px 24px rgba(67, 72, 114, 0.035), 0px 12px 10px rgba(67, 72, 114, 0.0456112),
	0px 4px 4px rgba(67, 72, 114, 0.07);

$button-blue-shadow: 0px 2px 4px rgba(62, 100, 200, 0.1), 0px 3px 8px rgba(62, 100, 200, 0.15),
	0px 4px 16px rgba(62, 100, 200, 0.4);

$button-white-shadow: 0px 2px 4px rgba(55, 59, 79, 0.05), 0px 3px 8px rgba(55, 59, 79, 0.08),
	0px 4px 16px rgba(55, 59, 79, 0.2);

$button-gray-shadow: 0px 2px 4px rgba(58, 58, 58, 0.05), 0px 3px 8px rgba(58, 58, 58, 0.08),
	0px 4px 16px rgba(58, 58, 58, 0.2);

$button-red-shadow: 0px 2px 4px rgba(235, 87, 87, 0.1), 0px 3px 8px rgba(235, 87, 87, 0.15),
	0px 4px 16px rgba(235, 87, 87, 0.4);

$button-green-shadow: 0px 2px 4px rgba(52, 162, 109, 0.1), 0px 3px 8px rgba(52, 162, 109, 0.15),
	0px 4px 16px rgba(52, 162, 109, 0.4);
