$color-app-main: #ffffff;
$color-app-background: #f4f4f4;
$color-landing-background: #f7f7f7;

$color-border-light: #e3e5e7;
$color-border: #d9d8dc;
$color-panel-border: #30323b26;

$color-text-black: #4d5063;
$color-input-placeholder: #959595;
$color-input-error: #ff8d8d;

$color-button-disabled: #cdcdcd;

$color-blue: #426edf;
$color-blue-focus: #5d83e4;
$color-blue-ghost: #5d83e41a;
$color-red: #eb5757;
$color-red-focus: #fd4747;
$color-red-ghost: #eb57571a;
$color-gray: #373b4f;
$color-gray-focus: #373b4f;
$color-gray-light: #f5f7f9;
$color-gray: #bdbfc5;
$color-green: #34a26d;
$color-green-focus: #34bd7a;
$color-green-ghost: #34bd7a1a;
$color-white: #fafafa;

$color-skill-none: #b9bbc0;
$color-skill-noob: #f2c94c;
$color-skill-junior: #f2994a;
$color-skill-middle: #eb5757;
$color-skill-senior: #4d5162;

$color-red-light-bg: #eb57571a;
$color-purple-light-bg: #df00ca1a;
$color-blue-light-bg: #5d83e41a;
$color-red-light-bg-2: #e82c2c1a;

$color-group-bg: #f5f7f9;

$color-link-tg: #279eda;
$color-link-vk: #2787f5;
$color-link-fb: #1778f2;

$color-avatar-coral: #ff9999;
$color-avatar-lagoon: #56ccf2;
$color-avatar-gray: #bdbdbd;
$color-avatar-purple: #de8afe;
$color-avatar-mustard: #ffb574;
$color-avatar-lavender: #a3bcff;
$color-avatar-mint: #62dba0;

$color-rose: #eb57571a;
